<template>
	<div class="about">
		<div class="child_accountNumbox">
			<div class="header_serachcontainer">
				<div class="left_input">
					<div class="list_input">
						<p class="input_title">用户姓名：</p>
						<el-input placeholder="请输入用户姓名" v-model="user_nickname" clearable>
						</el-input>
					</div>
					<!-- 	<div class="list_input">
						<p class="input_title">创建时间：</p>
						<el-date-picker v-model="date_value" @blur="get_choose_value" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</div> -->
					<div class="list_input">
						<p class="input_title">手机号：</p>
						<el-input placeholder="请输入手机号" v-model="user_phonenumber" clearable>
						</el-input>
					</div>

					<div class="submit_btn">
						<el-button type="primary" @click="serach_userfun" icon="el-icon-search">立即搜索</el-button>
					</div>
				</div>

				<div class="right_create ">
					<p class="create_btn" @click="is_create_pop = true">
						<span>成员新增</span> <i class="el-icon-arrow-right"></i>
					</p>
				</div>
			</div>

			<div class="tablecontainer">
				<el-table :data="tableData" border class="table_box" style="width: 100%"
					:height="'calc(100vh - 278px)'">
					<el-table-column align="center" prop="realname" label="姓名" width="140">
					</el-table-column>
					<!-- 	<el-table-column align="center" prop="avatar" label="头像" width="120">
						<template slot-scope="scope">
							<img class='avatar_img' :src="scope.row.avatar" alt="" />
						</template>
					</el-table-column> -->
					<el-table-column align="center" prop="mobile" label="手机号" width="180">
					</el-table-column>
					<el-table-column align="center" prop="fencheng" label="分成比例">
						<template slot-scope="scope">
							<p>
								{{ scope.row.fencheng ? scope.row.fencheng : "--"}}{{ scope.row.fencheng ? "%" : "" }}
							</p>
						</template>
					</el-table-column>

					<el-table-column align="center" prop="status" label="状态">
						<template slot-scope="scope">
							<el-tag
								:type="scope.row.status == 1 ? 'success' : 'warning'">{{ scope.row.status == 1 ? "正常" : "异常"  }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="createtime" label="创建时间" width="220">
					</el-table-column>
					<el-table-column align="center" label="操作" width="240">
						<template slot-scope="scope">
							<el-button @click="editer_user(scope.row)" type="primary" plain size="small">编辑</el-button>
							<el-button @click="delete_parsent(scope.row)" type="danger" plain
								size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="page_numbox">
					<el-pagination background layout="prev, pager, next" :total="data_num" :pager-count="11"
						@current-change="change_page">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="编辑" :close-on-click-modal="false" :visible.sync="dialogVisible" width="400px" top="30vh">
			<div class="edit_fenchengbox">
				<p class="input_title">分成修改：</p>
				<el-input placeholder="请输入分成比例"
					@input="set_userfencheng=set_userfencheng.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
					v-model="set_userfencheng" clearable>
					<template slot="append">%</template>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="edit_fenchengfun">确 定</el-button>
			</span>
		</el-dialog>
		<CreateAccount title="新增团队成员" :pop_show="is_create_pop" @downfun="down_popup" height="360"
			@create_success="create_successfun"></CreateAccount>
	</div>
</template>
<script>
	import CreateAccount from "../components/create_account.vue"
	export default {
		name: "about",
		components: {
			CreateAccount,
		},
		data() {
			return {
				dialogVisible: false,
				// 创建弹窗是否显示
				is_create_pop: false,

				// 表格数据
				tableData: [],
				// 页码
				page: 1,
				// 全部数据数量
				data_num: 0,

				user_nickname: "",
				// 手机号(搜索)
				user_phonenumber: "",
				// 创建时间(搜索)
				date_value: "",
				// 编辑用户分成
				set_userfencheng: "",

				show_userinfo: {},
			}
		},
		created() {
			this.get_teamlistfun(this.page)
		},
		methods: {
			// 分成编辑
			edit_fenchengfun() {
				let data = {
					id: this.show_userinfo.id,
					fencheng: this.set_userfencheng,
				}
				let token = localStorage.getItem("token")
				this.http.post_request("/team/edit", data, token).then((res) => {
					if (res.status == 200) {
						this.set_table_fenchengfun()
						this.dialogVisible = false
					}
				})
			},
			// 编辑成功之后设置item的分成
			set_table_fenchengfun() {
				this.tableData.forEach((item, t) => {
					if (item.id == this.show_userinfo.id) {

						this.tableData[t].fencheng = Number(this.set_userfencheng).toFixed(2)



						// 如果是整数 加(.00)
						// if (Number(this.set_userfencheng) % 1 === 0) {
						// 	this.tableData[t].fencheng = this.set_userfencheng + ".00"
						// } else {
						// 	this.tableData[t].fencheng = this.set_userfencheng
						// }

						this.set_userfencheng = ""
					}
				})
			},
			// 页码改变
			change_page(event) {
				this.page = event
				this.get_teamlistfun(event)
			},
			// 获取选择的时间
			get_choose_value(event) {
				console.log(event.value)
				let datearr = event.value
				datearr.forEach((item, t) => {
					if (t == 0) {
						console.log(item.getFullYear())
						console.log(item.getMonth() + 1)
					} else {
						console.log(item.getFullYear())
						console.log(item.getMonth() + 1)
					}
				})
			},

			//删除账号
			delete_parsent(item) {
				console.log(item)
				this.$confirm("此操作将删除该成员, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						let data = {
							id: item.id,
						}
						let that = this
						let token = localStorage.getItem("token")
						this.http.post_request("/team/del", data, token).then((res) => {
							if (res.status == 200) {
								console.log(res)

								that.tableData.forEach((info, t) => {
									if (info.id == item.id) {
										this.tableData.splice(t, 1)
									}
								})

								that.$message({
									type: "error",
									message: "删除成功!",
								})
							}
						})
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						})
					})
			},

			// 编辑
			editer_user(item) {
				this.dialogVisible = true
				this.show_userinfo = item;
				this.set_userfencheng = item.fencheng

			},

			// 创建账号成功（重新获取table数据）
			create_successfun() {
				this.down_popup()
				this.$message({
					type: "success",
					message: "创建账号成功",
				})
				this.tableData = []
				this.get_teamlistfun()
			},

			// 获取团队成员
			get_teamlistfun(page) {
				let data = {
					page: page,
					mobile: this.user_phonenumber,
					realname: this.user_nickname,
				}
				let token = localStorage.getItem("token")
				let that = this
				this.http.post_request("/team/index", data, token).then((res) => {
					console.log(res)
					if (res.status == 200) {
						that.tableData = res.data
						that.data_num = res.num;
						if (res.data.length == 0) {
							this.$message({
								type: "warning",
								message: "暂无数据",
							})
						}
					}
				})
			},
			serach_userfun() {
				if (this.user_phonenumber == '' && this.user_nickname == '') {
					this.$message({
						type: "warning",
						message: "请输入查询条件",
					})
					return
				}
				this.get_teamlistfun(this.page)
			},

			down_popup() {
				this.is_create_pop = false
			},
		},
	}
</script>
<style scoped>
	.about {
		height: calc(100% - 30px);
		padding: 15px;
		width: calc(100% - 30px);
		display: flex;
		flex-direction: column;
	}

	/* 去掉table 的滚动条 */
	/* .el-table__body-wrapper::-webkit-scrollbar {
		display: none;

	} */

	.header_serachcontainer {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.header_serachcontainer .left_input {
		display: flex;
		width: 100%;
	}

	.header_serachcontainer .left_input .list_input {
		display: flex;
		margin-right: 30px;
	}

	.header_serachcontainer .left_input .list_input .input_title {
		white-space: nowrap;
		font-size: 14px;
		color: #333333;
		margin: auto 8px auto 0;
	}

	.header_serachcontainer .left_input .list_input .choose_select span {
		display: flex;
	}

	.about .child_accountNumbox::-webkit-scrollbar {
		display: none;
	}

	.about .child_accountNumbox {
		height: 100%;
		border-radius: 5px;
		overflow-y: scroll;
		padding: 20px;
		background-color: #fff;
	}

	.tablecontainer {
		width: 100%;
		margin-top: 15px !important;
	}

	.tablecontainer .table_box .avatar_img {
		width: 50px;
		height: 50px;
		border-radius: 100%;
	}

	.right_create {
		width: 100%;
		display: flex;
		margin: auto 0;
		justify-content: flex-end;
	}

	.right_create .create_btn {
		display: flex;
		cursor: pointer;
		padding: 6px 10px;

		border: 1.5px solid #ffffff;
		transition-duration: 0.5s;
	}

	.right_create .create_btn:hover {
		border: 1.5px solid #1677ff;

		border-radius: 4px;
	}

	.right_create .create_btn span {
		font-size: 15px;
		color: #1677ff;
	}

	.right_create .create_btn i {
		font-size: 18px;
		margin: auto 0;
		color: #1677ff;
	}

	.edit_fenchengbox {
		width: 100%;
		/* border: 1px solid red; */
		display: flex;
	}

	.edit_fenchengbox p {
		white-space: nowrap;
		margin: auto 0;
	}
</style>