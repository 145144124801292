<template>
	<!-- 	<div class="popupContainer" :style="pop_show?'z-index:1000;opcity:0':'z-index:-100;opcity:1'"> -->
	<div :class="pop_show?'popupContainer pop_showbox':'popupContainer '">
		<div class="popup_center" :style="width||height?'width:'+width+'px;height:'+height +'px':''">
			<p class="titltext">{{title}}</p>
			<div class="dowon" @click="downfun">
				<span class="el-icon-error"></span>
			</div>
			<div class="create_inputbox">
				<!-- 	<div class="list_user">
					<span class="left_title">头像</span>
					<div class="avatar_box">
						<el-upload class="avatar-uploader" :action="baseURL+'/Base/upload'" :show-file-list="false"
							:on-success="uploadAvatarSuccess" :on-error='upload_error'>
							<div class="avatar" v-if="imageUrl!=''">
								<img :src="baseURL+imageUrl">
								<div class="showmask">
									<span class=" el-icon-plus avatar-uploader-icon"></span>
									<p>重新上传</p>
								</div>
							</div>
							<i v-if="imageUrl==''" class="el-icon-plus avatar-uploader-icon"></i>

						</el-upload>
						<span v-if="imageUrl==''" class="el-upload__tip" slot="tip">
							请上传头像
						</span>
					</div>
				</div> -->
				<div class="list_user">
					<span class="left_title">姓名</span>
					<div class="right_input">
						<el-input v-model="nickname" clearable placeholder="请输入昵称"></el-input>
					</div>
				</div>
				<div class="list_user">
					<span class="left_title">手机号</span>
					<div class="right_input">
						<el-input v-model="phone" clearable placeholder="请输入手机号"></el-input>
					</div>
				</div>

				<div class="list_user">
					<span class="left_title">分成比例</span>
					<div class="right_input">
						<el-input placeholder="请输入分成比例" v-model="divide_value">
							<template slot="append">%</template>
						</el-input>
					</div>
				</div>


				<!-- <div class="list_user">
					<span class="left_title">账户状态</span>
					<div class="right_input">
						<el-select class="status_select" @change="handleCommand" v-model="status_text"
							placeholder="请选择账号状态">
							<el-option v-for="(info,t) in order_statuslist" :key="t" :label="info" :value="info">
							</el-option>
						</el-select>
					</div>
				</div> -->
				<div class="submit_butn">
					<el-button class="submit" @click="create_submit" type="primary">立即创建</el-button>
				</div>



			</div>
		</div>
	</div>
</template>

<script>
	import {
		upload_url
	} from "../ulist/api.js"
	export default {
		name: 'Popup',
		emits: ['downfun', 'create_success'],
		props: ['pop_show', "width", "height", 'title'],
		data() {
			return {
				baseURL: upload_url,
				// // 头像
				// imageUrl: "",
				// 昵称
				nickname: "",
				// 手机号
				phone: "",
				// 分成比例
				divide_value: "",
				// 状态文字
				status_text: "",
				// 订单状态列表
				order_statuslist: [
					'正常',
					'异常',
				],

			}
		},
		created() {
			// console.log(this.$props)
		},
		methods: {

			// 头像上传成功
			uploadAvatarSuccess(event) {
				// console.log("成功" + event)
				if (event.status == 200) {
					this.imageUrl = event.data
				}

			},

			upload_error() {
				this.$message({
					type: 'error',
					message: '上传失败，请重新上传!'
				});
			},

			//文件上传之前获取的文件信息
			// beforeAvatarUpload(event) {
			// 	console.log(event)
			// },
			// 顶部筛选下拉选择
			handleCommand(event) {
				console.log(event)
				// this.status_text = this.order_statuslist[n]
			},
			// 立即创建
			create_submit() {
				// if (this.imageUrl == '') {
				// 	this.mesagefun("请上传账户头像")
				// 	return false
				// }
				if (this.nickname == '') {
					this.mesagefun("请输入账户昵称")
					return false
				}
				if (this.phone == '') {
					this.mesagefun("请输入账户手机号")
					return false
				}
				if (this.divide_value == '') {
					this.mesagefun("请输入账户分成比例")
					return false
				}
				// if (this.status_text == '') {
				// 	this.mesagefun("请选择账户状态")
				// 	return false
				// }
				let that = this;

				let data = {
					realname: this.nickname,
					mobile: this.phone,
					fencheng: this.divide_value,
				}
				let token = localStorage.getItem("token")
				this.http.post_request("/team/add", data, token).then(res => {
					console.log(res)

					if (res.status == 200) {
						this.nickname = '';
						this.phone = ''
						this.divide_value = ''
						this.status_text = ''
						that.$emit("create_success")
					}
				})
			},


			downfun() {
				this.$emit("downfun")
			},


			mesagefun(text) {
				// let data = {

				// }
				// let token = localStorage.getItem("token")
				// this.http.post_request("", data, token).then(res => {
				// 	console.log(res)
				// })
				this.$message({
					type: 'error',
					message: text
				});
			},

		}

	}
</script>
<style>
	.popupContainer {
		width: 100%;
		position: fixed;
		z-index: -100;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0);
		height: 100%;
		display: flex;
		transition-duration: 0.5s;
	}

	.pop_showbox {
		transition-duration: 0.5s;
		z-index: 100 !important;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.popupContainer .popup_center::-webkit-scrollbar {
		display: none;
	}

	.popupContainer .popup_center {
		width: 500px;
		background-color: #fff;
		height: 600px;
		margin: auto;
		padding: 20px;
		border-radius: 10px;
		position: relative;
		overflow-y: scroll;
	}

	.popupContainer .popup_center .titltext {
		font-size: 18px;
		margin-bottom: 20px;

	}

	.dowon {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.dowon span {
		cursor: pointer;
		font-size: 24px;
		transition-duration: 0.5s;
		color: #c9c9c9;
	}

	.dowon span:hover {
		color: #409EFF;
	}




	.create_inputbox .list_user {
		display: flex;
		margin-bottom: 30px;
		width: 100%;

	}

	.create_inputbox .list_user .left_title {
		margin: auto 20px auto 0;
		font-size: 14px;
		color: #000000;
		display: block;
		text-align: right;
		width: 80px;
	}

	.create_inputbox .list_user .left_title::before {
		content: "*";
		color: red;
		font-size: 16px;
	}

	.create_inputbox .list_user .avatar_box {
		position: relative;
	}

	.create_inputbox .list_user .avatar_box .avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		z-index: 10;
		overflow: hidden;
		width: 100px;
		z-index: 10;
		height: 100px;
	}

	.create_inputbox .list_user .avatar_box .avatar-uploader .el-upload {
		height: 100px;
		width: 100px;
	}

	.create_inputbox .list_user .avatar-uploader:hover {
		border-color: #409EFF;
	}

	.create_inputbox .list_user .avatar-uploader .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}




	.create_inputbox .list_user .el-upload__tip {
		height: 30px;
		text-align: center;
		font-size: 12px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		color: #8c939d;
	}

	.create_inputbox .list_user .avatar-uploader .avatar {
		width: 100%;
		height: 100%;
		position: relative;

	}

	.create_inputbox .list_user .avatar-uploader .avatar img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.create_inputbox .list_user .avatar-uploader .avatar .showmask {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		top: 0;
		z-index: -10;
		display: flex;
		/* transition-duration: 0.5s; */
	}

	.create_inputbox .list_user .avatar-uploader .avatar .showmask span {
		margin: auto;
		font-size: 28px;
		color: #ffffff;
	}

	.create_inputbox .list_user .avatar-uploader .avatar .showmask p {
		height: 30px;
		position: absolute;
		bottom: 0;
		left: 0;
		text-align: center;
		width: 100%;
		color: #ffffff;
		font-size: 12px;
	}

	.create_inputbox .list_user .avatar-uploader .avatar:hover .showmask {
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	.create_inputbox .list_user .right_input {
		width: 360px;
		/* height: 30px; */
	}

	.create_inputbox .list_user .right_input .status_select {
		width: 100%;
	}

	.create_inputbox .list_user .right_input .el-input-group__append {
		font-size: 22px;
	}

	.create_inputbox .submit_butn {
		width: 100%;
		display: flex;
		margin-top: 50px;
	}

	.create_inputbox .submit_butn .submit {
		margin: auto;
		width: 240px;
	}
</style>