import axios from "axios"
import showMessage from "./axios_message.js"
// const baseURL = "https://chang.chenxi.run";
const baseURL = "https://api.csmei.cn/api";
import router from '../router'

import {
	showFullScreenLoading,
	tryHideFullScreenLoading
} from "./axios_loading.js"

var request = axios.create({
	// 后台接口的基准地址
	baseURL: baseURL,
	timeout: 1000
})
var post_request = (api, data, headertext) => {
	if (typeof (data) == 'string') {
		headertext = data;
		data = {};
	}
	return new Promise((resolve, reject) => {
		data.manage_id = window.localStorage.getItem("default_region_id")
		request({
			url: baseURL + api,
			method: "POST",
			data: data,
			headers: {
				"Authorization": !headertext ? '' : headertext
			},
		}).then(res => {
			if (res) {
				if (res.data.status === 200) {
					resolve(res.data)
				}
			}
		})
			.catch(err => {

				reject(err)
				tryHideFullScreenLoading()
			})

	})
}

// 添加请求拦截器
request.interceptors.request.use((config) => {
	// if (!localStorage.getItem("token")) {
	// 	setTimeout(() => {
	// 		debugger
	// 		window.localStorage.removeItem("token")
	// 		router.go(0)
	// 	}, 1000)
	// } else {

	// }
	showFullScreenLoading()
	return config

}, function (error) {
	console.log("请求拦截")
	tryHideFullScreenLoading()
	//对相应错误做点什么
	showMessage("登录出错，请重试") // 显示错误信息
	return Promise.reject(error)
})



//拦截器响应
request.interceptors.response.use((response) => {
	tryHideFullScreenLoading()
	if (response.data.status == 200) {
		return response
	} else if (response.data.status == 101) {
		showMessage(response.data.msg ? response.data.msg : "token 已过期,请重新登录") // 显示错误信息
		setTimeout(() => {
			window.localStorage.removeItem("token")
			router.go(0)
		}, 1000)
	} else {
		showMessage(response.data.msg) // 显示错误信息
		if (response.data.status == 201) {
			setTimeout(() => {
				window.localStorage.removeItem("token")
				router.go(0)
			}, 1000)
		}
	}

}, function (error) {
	//对相应错误做点什么
	tryHideFullScreenLoading()
	showMessage('请求出错，请重试') // 显示错误信息
	console.log(error)
	// return Promise.reject(error)
})

export const upload_url = "http://chang.chenxi.run";
// export const upload_url = "";

export default {
	post_request,
}