<template>
	<div class="withdrawimgbox">
		<div class="center_container">
			<!-- 	收款方式 收款人 收款账号 收款码===》确认收款信息 -->
			<div class="list_linbox">
				<p class="lfet_title">收款方式：</p>
				<div class="radio">
					<el-radio v-model="radio" label="1">支付宝</el-radio>
					<el-radio v-model="radio" label="2">微信</el-radio>
				</div>
			</div>
			<div class="list_linbox">
				<p class="lfet_title">收款人：</p>
				<div class="right_input">
					<el-input placeholder="请输入收款人名称" v-model="collection_user" clearable>
					</el-input>
				</div>
			</div>
			<div class="list_linbox">
				<p class="lfet_title">收款人账号：</p>
				<div class="right_input">
					<el-input placeholder="请输入收款账号" v-model="collection_number" clearable>
					</el-input>
				</div>
			</div>

			<div class="list_linbox">
				<p class="lfet_title">提现金额：</p>
				<div class="right_input">
					<el-input placeholder="请输入提现金额" v-model="collection_price" clearable>
					</el-input>
				</div>
			</div>
			<div class="list_linbox">
				<p class="lfet_title">收款人码：</p>
				<div class="er_codebox">
					<!-- :before-upload="beforeAvatarUpload" -->
					<el-upload class="avatar-uploader" :action="baseURL+'/Base/upload'" :on-error='upload_error'
						:show-file-list="false" :on-success="handleAvatarSuccess">
						<div class="avatar" v-if="imageUrl!=''">
							<img :src="baseURL+imageUrl">
							<div class="showmask">
								<span class=" el-icon-plus avatar-uploader-icon"></span>
								<p>重新上传</p>
							</div>
						</div>
						<i v-if="imageUrl==''" class="el-icon-plus avatar-uploader-icon"></i>

					</el-upload>
					<span v-if="imageUrl==''" class="el-upload__tip" slot="tip">
						请上传收款码
					</span>
				</div>
			</div>


			<div class="sure_sumbtn">
				<el-button class="sure_showbtn" @click="show_popfun" type="primary">确认</el-button>
			</div>
		</div>
		<Popup title="确认收款信息" :width='400' :height='500' @downfun="down_popup" :pop_show='popup_show'>
			<div class="head_toast">
				<span class="el-icon-warning toast_icon"></span>
				<p class="toast_text">温馨提示，请确认提现信息准确无误</p>
			</div>

			<div class="list_met_code">
				<p class="left_title">收款码：</p>
				<img class="code_img" v-if="imageUrl!=''" :src="baseURL+imageUrl">
			</div>
			<div class="list_met_code">
				<p class="left_title">收款方式：</p>
				<div class="list_type" v-if="radio=='2'">
					<img class="icon " src="../assets/wechactpay.png" alt="">
					<p>微信</p>
				</div>
				<div class="list_type" v-else>
					<img class="icon " src="../assets/alipay.png" alt="">
					<p>支付宝</p>
				</div>
			</div>
			<div class="list_met_code">
				<p class="left_title">收款人：</p>
				<span class="righ_spantextname">{{collection_user}}</span>
			</div>
			<div class="list_met_code">
				<p class="left_title">收款账号：</p>
				<span class="righ_spantext">{{collection_number}}</span>
			</div>
			<div class="list_met_code">
				<p class="left_title">提现金额：</p>
				<span class="righ_spantext">{{collection_price}}</span>
			</div>
			<div class="get_waybox">
				<el-button class="get_waybtn" @click="submit_widthfun" type="primary">立即提现</el-button>
			</div>
		</Popup>
	</div>
</template>

<script>
	import {
		upload_url
	} from "../ulist/api.js"
	import Popup from "../components/HelloWorld.vue"
	export default {
		components: {
			Popup
		},
		data() {
			return {
				baseURL: upload_url,
				// 弹窗is_show
				popup_show: false,
				// 收款方式 1 微信 2 支付宝
				radio: '1',
				// 收款人
				collection_user: "",

				// 收款账号
				collection_number: "",
				// 收款码
				imageUrl: "",
				// 提现金额
				collection_price: "",
			}

		},
		created() {},

		methods: {
			// 关闭弹窗
			down_popup() {
				this.popup_show = false
			},
			// 二维码上传成功
			handleAvatarSuccess(event) {
				if (event.status == 200) {
					this.imageUrl = event.data
				}

			},

			upload_error() {
				this.$message({
					type: 'error',
					message: '上传失败，请重新上传!'
				});
			},

			show_popfun() {




				if (this.collection_user == '') {
					this.$message({
						type: 'error',
						message: '请输入收款人名称'
					})
					return false
				}
				if (this.collection_number == '') {
					this.$message({
						type: 'error',
						message: '请输入收款人账号'
					})
					return false
				}
				if (this.collection_price == '') {
					this.$message({
						type: 'error',
						message: '请输入提现金额'
					})
					return false
				}
				if (this.imageUrl == '') {
					this.$message({
						type: 'error',
						message: '请上传收款码'
					})
					return false
				}
				this.popup_show = true;
			},

			//文件上传之前获取的文件信息
			// beforeAvatarUpload(event) {
			// 	console.log(event)
			// },
			//立即体现
			submit_widthfun() {
				let data = {
					withdraw_to: this.collection_number,
					type: this.radio,
					realname: this.collection_user,
					pic: this.imageUrl,
					amount: this.collection_price
				}
				let token = localStorage.getItem("token")
				this.http.post_request("/user/withdraw", data, token).then(res => {

					if (res.status == 200) {
						this.$store.commit('changecount')
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.popup_show = false;
					}
					// 通知刷新列表页面
					setTimeout(() => {
						let num = this.$store.state.count + 1
						this.$store.commit('changecount', num)
					}, 10)
				})
			},

		}
	}
</script>

<style>
	.withdrawimgbox {
		height: calc(100% - 30px);
		padding: 15px;
		width: calc(100% - 30px);
		display: flex;
		flex-direction: column;
	}

	/* 去掉table 的滚动条 */
	.withdrawimgbox .center_container::-webkit-scrollbar {
		display: none;
	}

	.withdrawimgbox .center_container {
		height: 100%;
		border-radius: 5px;
		overflow-y: scroll;
		padding: 20px;
		background-color: #ffffff;
	}

	.list_linbox {
		display: flex;
		padding: 15px 0;
		/* height: 30px; */
	}

	.list_linbox .lfet_title {
		width: 100px;
		text-align: right;
		font-size: 14px;
		margin: auto 10px auto 0;
	}

	.list_linbox .radio {
		margin: auto 0;
		padding-top: 4px;
	}

	.list_linbox .right_input {
		width: 400px;
	}

	.list_linbox .er_codebox {
		position: relative;
	}

	.list_linbox .er_codebox .avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		width: 120px;
		z-index: 10;
		height: 120px;
	}

	.list_linbox .er_codebox .avatar-uploader .el-upload {
		height: 120px;
		width: 100%;
	}

	.list_linbox .avatar-uploader:hover {
		border-color: #409EFF;
	}

	.list_linbox .avatar-uploader .avatar-uploader-icon {
		font-size: 36px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.el-upload__tip {
		height: 40px;
		text-align: center;
		font-size: 12px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		color: #8c939d;
	}

	.list_linbox .avatar-uploader .avatar {
		width: 100%;
		height: 100%;
		position: relative;

	}

	.list_linbox .avatar-uploader .avatar img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.list_linbox .avatar-uploader .avatar .showmask {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		top: 0;
		z-index: -10;
		display: flex;
		/* transition-duration: 0.5s; */
	}

	.list_linbox .avatar-uploader .avatar .showmask span {
		margin: auto;
		font-size: 38px;
		color: #ffffff;
	}

	.list_linbox .avatar-uploader .avatar .showmask p {
		height: 40px;
		position: absolute;
		bottom: 0;
		left: 0;
		text-align: center;
		width: 100%;
		color: #ffffff;
		font-size: 12px;
	}

	.list_linbox .avatar-uploader .avatar:hover .showmask {
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	.withdrawimgbox .center_container .sure_sumbtn {
		width: 500px;
		margin-top: 40px;
		display: flex;
	}

	.sure_showbtn {
		margin: auto;
		width: 180px;
	}

	.head_toast {
		width: 100%;
		display: flex;
		margin-bottom: 20px;

	}

	.head_toast .el-icon-warning {
		color: #ff9d00;
		font-size: 20px;
	}

	.head_toast .toast_text {
		color: #ff9d00;
		font-size: 14px;
	}

	.list_met_code {
		width: 100%;
		display: flex;
		margin-bottom: 30px;
	}

	.list_met_code .left_title {
		width: 100px;
		text-align: right;
		font-size: 15px;
		margin: auto 10px auto 0;
	}

	.list_met_code .left_title::before {
		content: "*";
		color: #ff0000;
		font-size: 18px;
	}

	.list_met_code .code_img {
		width: 100px;
		border-radius: 6px;
		height: 100px;
		border: 1px dashed #b3afaf;
	}

	.list_met_code .list_type {
		display: flex;
		margin-right: 20px;
	}

	.list_met_code .list_type .icon {
		width: 30px;
		height: 30px;
	}

	.list_met_code .list_type p {
		font-size: 14px;
		margin: auto 0 auto 6px;
	}

	.list_met_code .righ_spantextname {
		color: #ff9d00;
		font-size: 15px;
	}

	.list_met_code .righ_spantext {
		font-size: 15px;
		color: #409EFF;
	}

	.get_waybox {
		width: 100%;
		display: flex;
	}

	.get_waybox .get_waybtn {
		margin: 10px auto 0;
		width: 200px;
	}
</style>