import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import http from "./ulist/api.js";
Vue.prototype.http = http;


Vue.config.productionTip = false

// Vue.use(router)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')