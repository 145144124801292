<template>
	<div class="withrawcontainer">
		<div class="withdraw_main">
			<div class="widthdraw_header">
				<div class='widhdrawbox'>
					<div class="widthdrao_info">
						<span>
							<i class="el-icon-s-marketing"></i>
						</span>
						<p>提现信息</p>
					</div>
					<div class="jump_btn" @click="goTowithdrawfun">
						<span>去提现</span>
						<i class='el-icon-arrow-right'></i>
					</div>
				</div>
				<ul class="list_detilul">
					<li class="list_earning">
						<span class="number" style="color: #00df25;">{{top_infopage.totalAmount}}</span>
						<p class="title">累计收入金额：（元）</p>
					</li>
					<li class="list_earning">
						<span class="number" style="color: #dfb51c;">{{top_infopage.balance}}</span>
						<p class="title">账户剩余金额：（元）</p>
					</li>
					<li class="list_earning">
						<span class="number" style="color: #fa2125;">{{top_infopage.todayWithdraw}}</span>
						<p class="title">今日提现金额（元）</p>

					</li>
					<li class="list_earning">
						<span class="number" style="color: #b0161b;">{{top_infopage.totalWithdraw}}</span>
						<p class="title">累计提现金额</p>
					</li>
					<li class="list_earning">
						<span class="number" style="color: #1677ff;">{{top_infopage.num}}</span>
						<p class="title">累计提现次数（次）</p>
					</li>
				</ul>
			</div>

			<div class="table_content">
				<el-table :data="tableData" border class="table_box" style="width: 100%"
					:height="'calc(100vh - 400px)'">
					<!-- 	<el-table-column align="center" prop="id" label="编号" width="80">
					</el-table-column>
					<el-table-column align="center" prop="mid" label="会员id" width="80">
					</el-table-column> -->
					<el-table-column align="center" prop="createtime" label="提现时间" width="180">
					</el-table-column>
					<el-table-column align="center" prop="realname" label="收款人" width="100">
					</el-table-column>

					<el-table-column align="center" prop="withdraw_to" label="提现账号" width="140">
					</el-table-column>
					<el-table-column align="center" prop="amount" label="提现金额" width="160">
						<template slot-scope="scope">
							<!-- 	<p>{{scope.row.order_status}}</p> -->
							<p class="all_pricenum"> <span>￥</span>{{scope.row.amount}}</p>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="fuwu" label="服务费(扣除)" width="150">
						<template slot-scope="scope">
							<p class="table_price">
								<span>￥</span>{{ scope.row.status==1?'-':"" }}{{scope.row.status==1? scope.row.fuwu:'0' }}
							</p>
						</template>
					</el-table-column>

					<el-table-column align="center" prop="amount" label="实际到账" width="160">
						<template slot-scope="scope">
							<!-- 	<p>{{scope.row.order_status}}</p> -->
							<p class="endall_price"> <span>￥</span>{{scope.row.amount-scope.row.fuwu }}</p>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="type" label="提现方式" width="120">
						<template slot-scope="scope">
							<!-- <p>暂无提现方式{{scope.row.status}}</p> -->
							<el-tag :type="scope.row.type==1?'':'success'">{{scope.row.type==1?' 支付宝':'微信'}}</el-tag>
						</template>
					</el-table-column>
					<!-- 	<el-table-column align="center" prop="updatetime" label="变更时间" width="140">
						<template slot-scope="scope">
							<p>{{scope.row.updatetime?scope.row.updatetime:'--'}}</p>
						</template>
					</el-table-column> -->
					<el-table-column align="center" prop="status" label="状态" width="130">
						<template slot-scope="scope">
							<!-- {{scope.row.status}} -->
							<el-tag
								:type="scope.row.status==1?'success':scope.row.status==2?'danger ':'warning'">{{scope.row.status==1?'已通过':scope.row.status==2?'已拒绝 ':'审核中'}}</el-tag>
						</template>
					</el-table-column>



					<el-table-column align="center" prop="reason" label="拒绝原因">
						<template slot-scope="scope">
							{{scope.row.reason}}
						</template>
					</el-table-column>


					<!-- 	<el-table-column align="center" label="操作" width="90">
						<template slot-scope="scope">
							<el-button @click="delete_record(scope.row)" type="danger" plain size="small">删除</el-button>
						</template>
					</el-table-column> -->


				</el-table>
				<div class="page_numbox">
					<el-pagination background layout="prev, pager, next" :pager-count='11' :total="data_num"
						@current-change="change_page">
					</el-pagination>
				</div>

			</div>
		</div>


	</div>
</template>

<script>
	export default {
		name: 'withdraw',
		data() {
			return {
				// 表格数据
				tableData: [],
				page: 1,

				data_num: 1,
				top_infopage: {},

				// 提现成功是否刷新数据
				is_width: true,


			}

		},
		created() {
			this.get_recordlistfun(this.page)
			this.get_withdrawinfofun()
		},
		computed: {
			vuexCount() {
				return this.$store.state.count
			}
		},
		watch: {
			vuexCount() {
				if (this.is_width) {
					this.is_width = false;
					this.get_recordlistfun(this.page)
				}

			}
		},
		methods: {
			// 页码改变
			change_page(event) {
				this.page = event;
				this.get_recordlistfun(event)
			},
			// 跳转提现
			goTowithdrawfun() {
				this.$router.push("/withdrawing")
			},

			// 获取提现信息
			get_withdrawinfofun() {
				let token = localStorage.getItem("token")
				this.http.post_request("/user/withdrawInfo", token).then(res => {
					if (res.status == 200) {
						this.top_infopage = res.data
					}
				})
			},

			get_recordlistfun(page) {
				let token = localStorage.getItem("token")
				let data = {
					page: page,
				}
				let that = this;
				this.http.post_request("/user/withdrawRecord", data, token).then(res => {

					setTimeout(() => {
						this.is_width = true;
					}, 100)
					if (res.status === 200) {

						that.tableData = res.data
						that.data_num = res.num
					} else {
						this.$message({
							type: 'error',
							message: '请求出错，请重新获取'
						});
					}
				})
			},



			// 删除提现记录
			// delete_record(item) {
			// 	console.log(item)
			// 	this.$confirm('此操作将除该条提现记录, 是否继续?', '提示', {
			// 		confirmButtonText: '确定',
			// 		cancelButtonText: '取消',
			// 		type: 'warning',
			// 		center: true
			// 	}).then(() => {
			// 		this.$message({
			// 			type: 'error',
			// 			message: '删除成功!'
			// 		});
			// 	}).catch(() => {
			// 		this.$message({
			// 			type: 'info',
			// 			message: '已取消删除'
			// 		});
			// 	});
			// },
		}


	}
</script>

<style>
	.withrawcontainer {
		height: calc(100% - 30px);
		padding: 15px;
		width: calc(100% - 30px);
		display: flex;
		flex-direction: column;
	}

	/* 去掉table 的滚动条 */
	/* .el-table__body-wrapper::-webkit-scrollbar {
		display: none;
	} */

	.withrawcontainer .withdraw_main::-webkit-scrollbar {
		display: none;
	}

	.withrawcontainer .withdraw_main {
		height: 100%;
		border-radius: 5px;
		overflow-y: scroll;
		/* padding: 20px; */
		background-color: #ffffff;
	}

	.widthdraw_header {
		width: 100%;
		min-width: 1000px;
		/* display: flex; */
	}

	.widthdraw_header .widhdrawbox {
		/* width: 100%; */
		display: flex;
		padding: 20px;
		justify-content: space-between;
		border-bottom: 1px solid #f9f9f9;

	}

	.widthdraw_header .widhdrawbox .widthdrao_info {
		display: flex;


	}

	.widthdraw_header .widhdrawbox .widthdrao_info span {

		width: 24px;
		height: 24px;
		margin: auto 0;
		display: flex;
		background-color: #1677ff;
		border-radius: 100%;
	}

	.widthdraw_header .widhdrawbox .widthdrao_info span i {
		margin: auto;
		font-size: 14px;
		transition-duration: 0.5s;
		color: #ffffff;
	}

	.widthdraw_header .widhdrawbox .widthdrao_info p {
		margin: auto 8px;
		font-size: 16px;
	}

	.widthdraw_header .widhdrawbox .jump_btn {
		margin: auto 0;
		cursor: pointer;
		display: flex;
		border: 1px solid #ffffff;
		transition-duration: 0.5s;
		color: #1677ff;
		padding: 4px 10px;
		border-radius: 4px;
	}

	.widthdraw_header .widhdrawbox .jump_btn:hover {
		border: 1px solid #1677ff;
	}

	.widthdraw_header .widhdrawbox .jump_btn i {
		font-size: 16px;
		color: #1677ff;
		margin: auto 0;
	}

	.widthdraw_header .widhdrawbox .jump_btn span {
		color: #1677ff;
		font-size: 14px;
		margin: auto 3px;
	}

	.list_detilul {
		display: flex;
		padding: 10px 20px;
		border-bottom: 2px solid #ededed;
		box-shadow: 0 2px 10px #ededed;
		justify-content: space-between;
	}

	.list_detilul .list_earning {
		list-style: none;
		padding: 10px 0 20px;
		width: 16%;
		position: relative;
	}

	.list_detilul .list_earning:last-child::after {
		display: none;
	}

	.list_detilul .list_earning::after {
		position: absolute;
		width: 1px;
		height: 50%;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		content: "";
		display: block;
		background-color: #ededed;
	}

	.list_detilul .list_earning .number {
		font-size: 24px;
	}

	.list_detilul .list_earning .title {
		margin-top: 10px;
		font-size: 15px;
	}

	.table_content {
		padding: 20px;
		min-width: 900px;
	}
</style>