import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "../views/index.vue"
import Home from "../views/home.vue";
import About from "../views/About.vue";
import Order from "../views/order.vue";
import Owner from "../views/owner.vue"
import User from "../views/user.vue"

import Earnings from '../views/earnings.vue'
import Withdraw from "../views/withdraw.vue"

import UserSet from "../views/user_set.vue"

import Login from "../views/login.vue"
import Widhdrawing from '../views/withdrawing.vue'
Vue.use(VueRouter)


const routes = [{
	path: '/',
	component: Index,
	children: [{
		path: "/",
		redirect: "/home",
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			title: "首页"
		}
	},
	{
		path: '/about',
		name: 'About',
		component: About,
		meta: {
			title: "团队成员"
		}
	},
	{
		path: '/order',
		name: 'Order',
		component: Order,
		meta: {
			title: "订单管理"
		}
	},
	{
		path: '/owner',
		name: 'Owner',
		component: Owner,
		meta: {
			title: "商户管理"
		}
	},
	{
		path: '/user',
		name: 'User',
		component: User,
		meta: {
			title: "用户管理"
		}
	},
	{
		path: '/earnings',
		name: 'Earnings',
		component: Earnings,
		meta: {
			title: "收益管理"
		}
	},
	{
		path: '/withdraw',
		name: 'Withdraw',
		component: Withdraw,
		meta: {
			title: "提现管理"
		}
	},
	{
		path: '/user_set',
		name: 'UserSet',
		component: UserSet,
		meta: {
			title: "密码修改"
		}
	},
	{
		path: '/withdrawing',
		name: 'Widhdrawing',
		component: Widhdrawing,
		meta: {
			title: "提现"
		}
	}
	]
},

{
	path: '/login',
	name: 'Login',
	component: Login,
	meta: {
		title: "登录"
	}
},

]



const router = new VueRouter({
	routes
})


router.beforeEach((to, form, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}

	let token = window.localStorage.getItem("token")
	if (!token && to.path != '/login') {
		next({
			path: '/login'
		})
	} else {
		next();
	}

})

export default router






// router.beforeEach((to) => {
// 	if (to.meta.title) {
// 		document.title = to.meta.title
// 	}

// })