<template>
	<div class="earningbox">
		<div class="earncontainer">
			<div class="header_serachbox">
				<div class="list_input">
					<p class="input_title">类型：</p>
					<!-- 	<el-input placeholder="请输入用户编号" v-model="duty_id" clearable>
					</el-input> -->

					<el-select v-model="duty_type" placeholder="请选择收益类型">
						<el-option label="增加" value="2"></el-option>
						<el-option label="减少" value="1"></el-option>
					</el-select>
				</div>
				<div class="list_input">
					<p class="input_title">创建时间：</p>
					<el-date-picker v-model="date_value" @blur="get_choose_value" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="submit_btn">
					<el-button type="primary" @click="serach_listfun" icon="el-icon-search">立即搜索</el-button>
				</div>
			</div>
			<div class="tablecontainer">
				<el-table :data="tableData" border class="table_box" style="width: 100%"
					:height="'calc(100vh - 278px)'">
					<!-- <el-table-column align="center" prop="id" label="编号" width="100">
					</el-table-column> -->
					<!-- 	<el-table-column align="center" prop="mid" label="用户">
					</el-table-column>
 -->
					<el-table-column align="center" prop="amount" label="金额" width="150">
						<template slot-scope="scope">
							<!-- 	<p>{{scope.row.order_status}}</p> -->
							<p class="table_price"> <span>￥</span>{{scope.row.amount}}</p>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="createtime" label="创建时间" width="200">
					</el-table-column>
					<el-table-column align="center" prop="type" label="类型" width="100">
						<template slot-scope="scope">
							<el-tag
								:type="scope.row.type==1?'success':'danger'">{{scope.row.type==1?'增加 ':'减少'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="beizhu" label="备注">
					</el-table-column>

					<!-- 	<el-table-column align="center" label="操作">
						<template slot-scope="scope">
							<el-button @click="delete_record(scope.row)" type="danger" plain size="small">删除</el-button>
						</template>
					</el-table-column> -->


				</el-table>
				<div class="page_numbox">
					<el-pagination background layout="prev, pager, next" :pager-count='11' :total="data_num"
						@current-change="change_page">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'earnings',
		data() {
			return {
				tableData: [],
				// 页码
				page: 1,
				// 全部数据数量
				data_num: 0,

				// 类型
				duty_type: "",

				// 创建时间
				date_value: "",
				// 开始时间
				begin_time: "",
				// 结束时间
				end_time: "",


			}

		},
		created() {
			this.get_amountlistfun(this.page)
		},
		methods: {

			// 删除提现记录
			delete_record(item) {
				console.log(item)
				this.$confirm('此操作将除该条提现记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$message({
						type: 'error',
						message: '删除成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 获取选择的时间
			get_choose_value(event) {
				let datearr = event.value;
				// let datestart = "";
				// let date_end = "";
				datearr.forEach((item, t) => {
					if (t == 0) {
						// 开始时间
						let year = item.getFullYear()
						let month = item.getMonth() + 1

						if (month < 10) {
							month = "0" + month
						}
						let day = item.getDate()
						if (day < 10) {
							day = "0" + day
						}
						this.begin_time = year + '-' + month + "-" + day
					} else {
						// 开始时间
						let year = item.getFullYear()
						let month = item.getMonth() + 1

						if (month < 10) {
							month = "0" + month
						}
						let day = item.getDate()
						if (day < 10) {
							day = "0" + day
						}
						this.end_time = year + '-' + month + "-" + day
					}
				})
			},

			// 页码改变
			change_page(event) {

				this.page = event;
				this.get_amountlistfun(event)
			},
			// 获取表格数据
			get_amountlistfun(page) {
				let token = localStorage.getItem("token")
				let data = {
					page: page,
					begin: this.begin_time,
					end: this.end_time,
					type: this.duty_type
				}
				let that = this;
				this.http.post_request("/user/amountRecord", data, token).then(res => {
					if (res.status === 200) {
						that.tableData = res.data
						that.data_num = res.num;
						if (res.data.length == 0) {
							this.$message({
								type: "warning",
								message: "暂无数据",
							})
						}
					} else {
						this.$message({
							type: 'error',
							message: '请求出错，请重新获取'
						});
					}
				})
			},

			serach_listfun() {
				if (this.begin_time == "" && this.end_time == "" && this.duty_type == "") {
					this.$message({
						type: "warning",
						message: "请输入查询条件",
					})

					return
				}
				this.get_amountlistfun(this.page)
			},
		}
	}
</script>

<style scoped>
	.earningbox {
		height: calc(100% - 30px);
		padding: 15px;
		width: calc(100% - 30px);
		display: flex;
		flex-direction: column;
	}

	/* 去掉table 的滚动条 */
	/* 	.el-table__body-wrapper::-webkit-scrollbar {
		display: none;
	} */

	.earningbox .earncontainer::-webkit-scrollbar {
		display: none;
	}

	.earningbox .earncontainer {
		height: 100%;
		border-radius: 5px;
		overflow-y: scroll;
		padding: 20px;
		background-color: #ffffff;
	}


	.header_serachbox {
		display: flex;
	}

	.header_serachbox .list_input {
		display: flex;
		margin-right: 30px;
	}

	.header_serachbox .list_input .input_title {
		white-space: nowrap;
		font-size: 14px;
		color: #333333;
		margin: auto 8px auto 0;
	}

	.header_serachbox .list_input .choose_select span {
		display: flex;
	}



	.earningbox .earncontainer .tablecontainer {
		width: 100%;
		margin-top: 20px !important;
	}

	.tablecontainer .table_box .avatar_img {
		width: 50px;
		height: 50px;
		border-radius: 100%;
	}

	.page_numbox {
		display: flex;
		margin-top: 20px;
		justify-content: flex-end;
	}
</style>