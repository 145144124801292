<template>
	<!-- 	<div class="popupContainer" :style="pop_show?'z-index:1000;opcity:0':'z-index:-100;opcity:1'"> -->
	<div :class="pop_show?'popupContainer pop_showbox':'popupContainer '">
		<div class="popup_center" :style="width||height?'width:'+width+'px;height:'+height +'px':''">
			<p class="titltext">{{title}}</p>
			<div class="dowon" @click="downfun">
				<span class="el-icon-error"></span>
			</div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Popup',
		emits: ['downfun'],
		props: ['pop_show', "width", "height", 'title'],
		data() {
			return {}
		},
		created() {
			// console.log(this.$props)
		},
		methods: {
			downfun() {
				this.$emit("downfun")
			}

		}

	}
</script>
<style>
	.popupContainer {
		width: 100%;
		position: fixed;
		z-index: -100;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0);
		height: 100%;
		display: flex;
		transition-duration: 0.5s;
	}

	.pop_showbox {
		transition-duration: 0.5s;
		z-index: 100 !important;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.popupContainer .popup_center::-webkit-scrollbar {
		display: none;
	}

	.popupContainer .popup_center {
		width: 520px;
		background-color: #fff;
		height: 600px;
		margin: auto;
		padding: 20px;
		border-radius: 10px;
		position: relative;
		overflow-y: scroll;
	}

	.popupContainer .popup_center .titltext {
		font-size: 18px;
		margin-bottom: 20px;

	}

	.dowon {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.dowon span {
		cursor: pointer;
		font-size: 24px;
		transition-duration: 0.5s;
		color: #c9c9c9;
	}

	.dowon span:hover {
		color: #409EFF;
	}
</style>