<template>
  <div class="loginContainer">
    <div class="bigbox">
      <h1>长寿美管理中心</h1>
      <div class="list_inputbox">
        <input
          id="phone"
          type="text"
          v-model="phone_num"
          @keyup="input_keyfun($event, 'phone')"
          required
          autocomplete="off"
        />
        <label for="phone">请输入账号</label>
      </div>
      <div class="list_inputbox">
        <input
          id="pwd"
          type="password"
          v-model="pwdtext"
          @keyup="input_keyfun($event, 'pwd')"
          required
          autocomplete="off"
        />
        <label for="pwd">请输入密码</label>
      </div>
      <button class="login_btn" @click="loginbtnfun">
        登录
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        phone_num: "",
        pwdtext: "",
      }
    },
    mounted() {
      // 绑定enter事件
      this.enterKeyup()
    },
    destroyed() {
      // 销毁enter事件
      this.enterKeyupDestroyed()
    },
    methods: {
      input_keyfun(event, text) {
        console.log(text)
        if (event.keyCode == 13) {
          this.loginbtnfun()
          // if(text=="phone"){
          // 	console.log("phone 回车")
          // }
        }
      },
      loginbtnfun() {
        if (this.phone_num == "") {
          this.$message({
            type: "error",
            message: "请输入账号!",
          })
          return
        }
        if (this.pwdtext == "") {
          this.$message({
            type: "error",
            message: "请输入密码!",
          })
          return
        }
        let data = {
          mobile: this.phone_num,
          password: this.pwdtext,
        }
        this.http.post_request("/Login/index", data).then((res) => {
          this.$store.commit("user_infodata", res.data.user)
          window.localStorage.setItem("token", res.data.token)
          let mlistdata = res.data.mlist
          window.localStorage.setItem("regionlist", JSON.stringify(mlistdata))
          window.localStorage.setItem("default_region_id", res.data.user.id)
          window.localStorage.setItem(
            "region_create_time",
            res.data.user.createtime
          )
          setTimeout(() => {
            this.$router.push("/home")
          }, 1000)
        })
      },

      enterKeyupDestroyed() {
        document.removeEventListener("keyup", this.enterKey)
      },
      enterKeyup() {
        document.addEventListener("keyup", this.enterKey)
      },
    },
  }
</script>

<style>
  .loginContainer {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg");
  }

  .bigbox {
    width: 800px;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    text-align: center;
    box-shadow: 5px 8px 8px rgba(0, 0, 0, 0.9);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
  }

  .bigbox h1 {
    position: relative;
    color: white;
    font-size: 30px;
    margin-bottom: 30px;
  }

  .list_inputbox {
    width: 60%;
    margin: 30px auto;
    height: 50px;
    border-bottom: 1px solid #fff;
    position: relative;
  }

  .list_inputbox input {
    width: 100%;
    height: 100%;
    color: #fff;
    padding-top: 20px;
    box-sizing: border-box;
    background: transparent;
    border: 0;
    outline: none;
    font-size: 18px;
  }

  .list_inputbox input:focus + label,
  .list_inputbox input:valid + label {
    top: 0px;
    font-size: 14px;
    color: #03e9f4;
  }

  .list_inputbox label {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 15px;
    font-size: 18px;
    color: #03e9f4;
    transition: all 0.5s linear;
  }

  .bigbox .login_btn {
    padding: 15px 0px;
    margin: 40px auto;
    width: 50%;
    background-color: rgba(1, 131, 140, 0.1);
    color: #03e9f4;
    position: relative;
    overflow: hidden;
    border: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }

  .bigbox .login_btn:hover {
    border-radius: 5px;
    color: #fff;
    background: #03e9f4;
    box-shadow: 0 0 5px 0 #03e9f4, 0 0 25px 0 #03e9f4, 0 0 50px 0 #03e9f4,
      0 0 100px 0 #03e9f4;
    transition: all 1s linear;
  }

  .bigbox .login_btn > span {
    position: absolute;
  }

  .bigbox .login_btn > span:nth-child(1) {
    width: 100%;
    height: 2px;
    background: -webkit-linear-gradient(left, transparent, #03e9f4);
    left: -100%;
    top: 0px;
    animation: line1 1s 0s linear infinite;
  }

  @keyframes line1 {
    50%,
    100% {
      left: 100%;
    }
  }

  .bigbox .login_btn > span:nth-child(2) {
    width: 2px;
    height: 100%;
    background: -webkit-linear-gradient(top, transparent, #03e9f4);
    right: 0px;
    top: -100%;
    animation: line2 1s 0.5s linear infinite;
  }

  @keyframes line2 {
    50%,
    100% {
      top: 100%;
    }
  }

  .bigbox .login_btn > span:nth-child(3) {
    width: 100%;
    height: 2px;
    background: -webkit-linear-gradient(left, #03e9f4, transparent);
    left: 100%;
    bottom: 0px;
    animation: line3 1s 1s linear infinite;
  }

  @keyframes line3 {
    50%,
    100% {
      left: -100%;
    }
  }

  .bigbox .login_btn > span:nth-child(4) {
    width: 2px;
    height: 100%;
    background: -webkit-linear-gradient(top, transparent, #03e9f4);
    left: 0px;
    top: 100%;
    animation: line4 1s 1.5s linear infinite;
  }

  @keyframes line4 {
    50%,
    100% {
      top: -100%;
    }
  }
</style>
