<template>
  <div class="index">
    <el-container>
      <el-aside width="200px">
        <div class="left-barbox">
          <div class="left_bar_logo">
            <img
              src="https://chat-demo.mddai.cn/resource/image/adminapi/default/web_logo.jpg"
              alt=""
            />
            <p class="titltext">长寿美管理中心</p>
          </div>
          <ul class="link_barbox">
            <li class="list_link">
              <router-link
                :exact="true"
                active-class="show_active"
                class="list_bar"
                to="/home"
              >
                <div class="btnbox">
                  <span class="iconfont icon-31shouyexuanzhong"></span
                  ><i>首页</i>
                </div>
              </router-link>
            </li>
            <li class="list_link">
              <router-link
                :exact="true"
                active-class="show_active"
                class="list_bar"
                to="/about"
              >
                <div class="btnbox">
                  <span class="iconfont icon-zhanghuguanli"></span>
                  <i>团队成员</i>
                </div>
              </router-link>
            </li>
            <li class="list_link">
              <router-link
                :exact="true"
                active-class="show_active"
                class="list_bar"
                to="/order"
              >
                <div class="btnbox">
                  <span class="iconfont icon-dingdanguanli"></span>
                  <i>订单管理</i>
                </div>
              </router-link>
            </li>
            <li class="list_link">
              <router-link
                :exact="true"
                active-class="show_active"
                class="list_bar"
                to="/owner"
              >
                <div class="btnbox">
                  <span class="iconfont icon-shanghuguanli2"></span
                  ><i>商户列表</i>
                </div>
              </router-link>
            </li>
            <!-- 	<li class="list_link">
							<router-link :exact="true" active-class="show_active" class="list_bar" to="/user">
								<div class="btnbox">
									<span class="iconfont icon-user-fill"></span><i>用户管理</i>
								</div>
							</router-link>
						</li> -->
            <!-- <li class="list_link">
              <router-link
                :exact="true"
                active-class="show_active"
                class="list_bar"
                to="/earnings"
              >
                <div class="btnbox">
                  <span class="iconfont icon-tubiao207"></span><i>收益管理</i>
                </div>
              </router-link>
            </li> -->
            <!-- <li class="list_link">
              <router-link
                :exact="true"
                active-class="show_active"
                class="list_bar"
                to="/withdraw"
              >
                <div class="btnbox">
                  <span class="iconfont icon-tixian"></span> <i>提现管理</i>
                </div>
              </router-link>
            </li> -->
          </ul>
        </div>
      </el-aside>
      <el-main>
        <div class="righ_container">
          <div class="headerbox">
            <div class="right_topbox">
              <div class="left_box">
                <div class="icon" @click="refresh_pagefun">
                  <span class="el-icon-refresh"></span>
                </div>
                <div class="navbox">
                  <p>{{ top_showtext }}</p>
                </div>
              </div>
              <div class="righ_avatarbox">
                <el-dropdown @command="set_originbtn">
                  <div class="righ_orginbox">
                    <p class="parsent_orgintext">
                      当前管理地区：<span>{{ regionlist_title }}</span
                      ><i class="el-icon-arrow-down"></i>
                    </p>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(item, i) in regionlist"
                      :key="i"
                      :command="i"
                      style="font-size: 12px;"
                      >{{ item.title }}</el-dropdown-item
                    >
                    <!-- <el-dropdown-item command="3" style="font-size: 12px;">密码修改</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown @command="user_setbtn">
                  <div class="righ_user">
                    <img
                      class="avatar"
                      v-if="user_data.avatar"
                      :src="user_data.avatar"
                      alt=""
                    />
                    <div class="name">
                      <p>{{ user_data.nickname }}</p>
                      <span class="el-icon-arrow-down"></span>
                    </div>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="0" style="font-size: 12px;"
                      >密码修改</el-dropdown-item
                    >

                    <el-dropdown-item command="1" style="font-size: 12px;"
                      >退出登录</el-dropdown-item
                    >
                    <!-- <el-dropdown-item command='2' style="font-size: 12px;">清除缓存</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="right_bottomlinkbox">
              <li
                class="list_line_link"
                v-for="(item, i) in page_toprouterlist"
                :key="i"
              >
                <div
                  :class="parsent_url == item.url ? 'top_barshow' : 'top_bar'"
                  @click="jump_page(i)"
                >
                  <p class="list_textname">
                    {{ item.text }}
                    <span
                      v-if="page_toprouterlist.length == 1 ? false : true"
                      class="el-icon-close"
                      @click.stop="delete_lable($event, i)"
                    ></span>
                  </p>
                </div>
              </li>
            </div>
          </div>
          <div class="mainpagebox">
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
  export default {
    name: "index",
    data() {
      return {
        // 页面顶部导航
        page_toprouterlist: [],
        // 顶部导航
        top_showtext: "",
        // 路由路径
        parsent_url: "",

        top_userdata: {},

        user_data: {},
        regionlist: [],
        regionlist_title: "",
        region_create_time: "",
      }
    },
    watch: {
      $route: function(to) {
        let route_info = to
        this.parsent_url = route_info.path
        if (!this.page_toprouterlist.find((object) => object.url == to.path)) {
          this.set_top_navfun(route_info.path)
        } else {
          this.top_showtext = this.back_urlname(to.path)
        }
      },
    },
    created() {
      this.set_top_navfun(this.$router.history.current.path)
      this.parsent_url = this.$router.history.current.path

      // 在页面加载时读取sessionStorage里的状态信息
      if (
        localStorage.getItem("store") &&
        localStorage.getItem("store") != "null" &&
        localStorage.getItem("store") != "{}"
      ) {
        this.$store.commit(
          "user_infodata",
          JSON.parse(localStorage.getItem("store"))
        )
        localStorage.removeItem("store")
      }

      if (
        localStorage.getItem("store") == "null" ||
        localStorage.getItem("store") == "{}"
      ) {
        localStorage.removeItem("store")
      }

      window.addEventListener("beforeunload", () => {
        localStorage.setItem(
          "store",
          JSON.stringify(this.$store.state.user_info)
        )
      })

      if (this.$store.state.user_info && this.$store.state.user_info.avatar) {
        this.user_data = this.$store.state.user_info
      }

      this.regionlist = JSON.parse(window.localStorage.getItem("regionlist"))
      this.region_create_time = window.localStorage.getItem(
        "region_create_time"
      )

      this.set_regiontitlefun(window.localStorage.getItem("default_region_id"))
    },
    methods: {
      // 设置当前管理地区title
      set_regiontitlefun(id) {
        if (!id) {
          return
        }
        if (this.regionlist.length > 0) {
          this.regionlist.forEach((item) => {
            if (item.id == id) {
              this.regionlist_title = item.title
              // this.region_tiime=item.createtime
              setTimeout(() => {
                this.$store.commit("regintitle", this.regionlist_title)
                this.$store.commit("setcreate_time", this.region_create_time)
              }, 300)
            }
          })
        }
      },
      // 跳转
      jump_page(t) {
        this.$router.push(this.page_toprouterlist[t].url)
      },
      // 删除
      delete_lable(event, t) {
        // event.stopPropagation();
        if (this.$route.path == this.page_toprouterlist[t].url) {
          if (t == 0) {
            this.page_toprouterlist.splice(0, 1)
            setTimeout(() => {
              this.$router.push(this.page_toprouterlist[0].url)
            }, 80)
          } else if (t == this.page_toprouterlist.length - 1) {
            this.page_toprouterlist.splice(t, 1)
            setTimeout(() => {
              this.$router.push(
                this.page_toprouterlist[this.page_toprouterlist.length - 1].url
              )
            }, 80)
          } else {
            this.page_toprouterlist.splice(t, 1)
            setTimeout(() => {
              this.$router.push(this.page_toprouterlist[t].url)
            }, 80)
          }
        } else {
          this.page_toprouterlist.splice(t, 1)
        }
      },

      // 刷新当前页面
      refresh_pagefun() {
        this.$router.go(0)
      },
      // 设置顶部导航
      set_top_navfun(url) {
        this.top_showtext = this.back_urlname(url)
        let obj = {
          text: this.back_urlname(url),
          url: url,
        }
        this.page_toprouterlist.push(obj)
      },

      user_setbtn(n) {
        console.log(n)
        //  n==0 个人设置
        // n== 1 退出登录
        if (n == 0) {
          this.$router.push("/user_set")
        }
        if (n == 1) {
          this.$store.commit("user_infodata", {})
          window.localStorage.removeItem("token")
          this.$router.go(0)
        }
      },

      set_originbtn(n) {
        if (
          this.regionlist[n].id ==
          window.localStorage.getItem("default_region_id")
        ) {
          return false
        }
        window.localStorage.setItem("default_region_id", this.regionlist[n].id)
        this.regionlist_title = this.regionlist[n].title
        this.$store.commit("regintitle", this.regionlist_title)

        setTimeout(() => {
          this.refresh_pagefun()
        }, 10)
      },
      back_urlname(url) {
        let url_name = ""
        switch (url) {
          case "/home":
            url_name = "首页"
            break
          case "/about":
            url_name = "团队成员"
            break
          case "/order":
            url_name = "订单管理"
            break
          case "/owner":
            url_name = "商户列表"
            break
          case "/user":
            url_name = "用户管理"
            break
          case "/earnings":
            url_name = "收益管理"
            break
          case "/withdraw":
            url_name = "提现管理"
            break
          case "/user_set":
            url_name = "密码修改"
            break
          case "/withdrawing":
            url_name = "立即提现"
            break
          default:
            url_name = "其他"
            break
        }
        return url_name
      },
    },
  }
</script>
<style>
  * {
    margin: 0;
    padding: 0;
  }

  .index {
    width: 100%;
    height: 100vh;
    display: flex;
  }

  .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar- {
    display: none;
  }

  .el-aside {
    background-color: #d3dce6;
    color: #333;
  }

  .left-barbox {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: #001529;
  }

  .left-barbox::-webkit-scrollbar {
    display: none;
  }

  .left_bar_logo {
    width: 100%;
    display: flex;
    padding: 20px 0;
  }

  .left_bar_logo img {
    width: 36px;
    margin: auto 10px;
    border-radius: 2px;
    height: 36px;
  }

  .left_bar_logo .titltext {
    color: #fff;
    white-space: nowrap;
    /* font-weight: 600; */
    font-size: 16px;
    margin: auto 0;
  }

  .el-main {
    padding: 0;
    background-color: #e9eef3;
    color: #333;
  }

  .link_barbox {
    padding: 0px 18px 0;
  }

  .link_barbox .list_link {
    width: 100%;
    list-style: none;
  }

  .link_barbox .list_link .list_bar {
    padding: 14px 0;
    margin: 5px 0;
    border-radius: 5px;
    display: block;

    text-decoration: none;
  }

  .link_barbox .list_link .list_bar .btnbox {
    color: #bababa;
    display: flex;
    padding-left: 30px;
  }

  .link_barbox .list_link .list_bar .btnbox span {
    margin: auto 10px auto 0;
    font-size: 18px;
  }

  .link_barbox .list_link .list_bar .btnbox i {
    font-style: normal;
    margin: auto 0;
    font-size: 15px;
    white-space: nowrap;
  }

  .link_barbox .list_link .list_bar:hover .btnbox span {
    font-size: 18px;
    color: #ffffff;
    /* font-size: 16px; */
  }

  .link_barbox .list_link .list_bar:hover .btnbox i {
    color: #ffffff;
    white-space: nowrap;
    font-size: 15px;
  }

  .link_barbox .list_link .show_active .btnbox span {
    margin: auto 10px auto 0;
    font-size: 18px;
    color: #ffffff;
  }

  .link_barbox .list_link .show_active .btnbox i {
    color: #ffffff;
    font-size: 15px;
  }

  .link_barbox .list_link .show_active {
    background-color: #1677ff;
    color: #ffffff;
  }

  /* 右侧内容区域 */
  .righ_container {
    width: 100%;
    overflow: hidden;

    /* background-color: #fff; */
  }

  .headerbox {
    /* height: 100px; */
    min-width: 900px;
    background-color: #fff;
  }

  .headerbox .right_topbox {
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #d8d8d8;
  }

  .righ_avatarbox {
    margin: auto 0;
    display: flex;
  }

  .righ_orginbox {
    margin-right: 20px;
    display: flex;
  }

  .righ_orginbox .parsent_orgintext {
    font-size: 14px;
  }

  .righ_orginbox .parsent_orgintext span {
    color: #1677ff;
    cursor: pointer;
  }

  .headerbox .right_topbox .left_box {
    display: flex;
  }

  .headerbox .right_topbox .left_box .icon {
    margin: auto 10px auto 0;
  }

  .headerbox .right_topbox .left_box .icon:hover span {
    color: #1677ff;
    cursor: pointer;
  }

  .headerbox .right_topbox .left_box .icon span {
    font-size: 18px;
  }

  .headerbox .right_topbox .left_box .navbox {
    margin: auto 0;
  }

  .headerbox .right_topbox .left_box .navbox p {
    color: #333333;
    font-size: 14px;
  }

  .el-dropdown {
    margin: auto 0;
  }

  .headerbox .right_topbox .righ_user {
    display: flex;
    margin: auto 0;
    cursor: pointer;
  }

  .headerbox .right_topbox .righ_user .avatar {
    width: 32px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    height: 32px;
  }

  .headerbox .right_topbox .righ_user .name {
    display: flex;
    margin: auto 0px auto 10px;
  }

  .headerbox .right_topbox .righ_user .name p {
    font-size: 15px;
    color: #333333;
  }

  .headerbox .right_topbox .righ_user .name span {
    margin: auto 5px;
  }

  .headerbox .right_bottomlinkbox {
    display: flex;
    padding: 0 20px;
    height: 34px;
  }

  .headerbox .right_bottomlinkbox .list_line_link {
    list-style: none;
  }

  .headerbox .right_bottomlinkbox .list_line_link .top_bar {
    text-decoration: none;
    padding: 0px 20px;
    height: 100%;
    margin-top: -2px;
    display: flex;
    border-top: 2px solid rgba(255, 255, 255, 0);
    cursor: pointer;
    font-size: 14px;
  }

  .headerbox .right_bottomlinkbox .list_line_link .top_bar .list_textname {
    margin: auto 0;
  }

  .headerbox .right_bottomlinkbox .list_line_link .top_barshow {
    text-decoration: none;
    padding: 0px 20px;
    height: 100%;
    margin-top: -2px;
    display: flex;
    border-top: 2px solid #1677ff;
    cursor: pointer;
  }

  .headerbox .right_bottomlinkbox .list_line_link .top_barshow .list_textname {
    margin: auto 0;
    font-size: 14px;
    color: #1677ff;
    position: relative;
  }

  /* .headerbox
    .right_bottomlinkbox
    .list_line_link
    .top_barshow */
  .list_textname::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    border-radius: 100%;
    left: -10px;
    top: 48%;
    transform: translateY(-50%);
    background-color: #1677ff;
  }

  .mainpagebox {
    width: 100%;

    height: calc(100vh - 90px);
  }
</style>
