<template>
  <div class="order_page">
    <div class="order_container">
      <div class="header_serachbox">
        <div class="list_input">
          <p class="input_title">订单号：</p>
          <el-input placeholder="请输入订单号" v-model="order_num" clearable>
          </el-input>
        </div>
        <div class="list_input">
          <p class="input_title">下单时间：</p>
          <el-date-picker
            v-model="date_value"
            @blur="get_choose_value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="list_input">
          <p class="input_title">订单状态：</p>
          <!-- <el-input placeholder="请输入订单状态" v-model="order_num" clearable>
					</el-input> -->

          <el-select v-model="order_status" placeholder="请选择订单状态">
            <el-option label="全部" value=""></el-option>
            <el-option label="已收货" value="1"></el-option>
            <el-option label="已付款" value="2"></el-option>
            <el-option label="已失效" value="3"></el-option>
          </el-select>
        </div>
        <div class="submit_btn">
          <el-button type="primary" @click="searchbtnfun" icon="el-icon-search"
            >立即搜索</el-button
          >
        </div>
      </div>

      <div class="tablecontainer">
        <el-table
          :data="tableData"
          border
          class="table_box"
          style="width: 100%;"
          :height="'calc(100vh - 278px)'"
        >
          <!-- <el-table-column align="center" prop="id" label="序号" width="80">
					</el-table-column> -->
          <el-table-column
            align="center"
            prop="pic_url"
            label="商品图片"
            width="100"
          >
            <template slot-scope="scope">
              <img class="shop_img" :src="scope.row.pic_url" alt="" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="title" label="商品名称">
            <template slot-scope="scope">
              <p class="shop_table_title">{{ scope.row.title }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="order_sn"
            label="订单号"
            width="200"
          >
            <template slot-scope="scope">
              <p class="shop_table_title">{{ scope.row.order_sn }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="amount"
            label="预估收益"
            width="130"
          >
            <template slot-scope="scope">
              <p class="table_priceto"><span>￥</span>{{ scope.row.amount }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="payment"
            label="付款金额"
            width="130"
          >
            <template slot-scope="scope">
              <p class="table_price"><span>￥</span>{{ scope.row.payment }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="pay_time"
            label="支付时间"
            width="170"
          >
            <template slot-scope="scope">
              <p class="create_timetext">{{ scope.row.pay_time }}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="order_receive_time"
            label="收货时间"
            width="170"
          >
            <template slot-scope="scope">
              <p class="get_timetext">{{ scope.row.order_receive_time }}</p>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="order_status_trans"
            label="订单状态"
            width="100"
          >
            <template slot-scope="scope">
              <div>
                <!-- <el-tag>{{ scope.row.order_status_trans }}</el-tag> -->
                <el-tag
                  :type="
                    scope.row.order_status_trans == 1
                      ? 'warning'
                      : scope.row.order_status_trans == 2
                      ? ''
                      : 'danger'
                  "
                  >{{
                    scope.row.order_status_trans == 1
                      ? "已收货"
                      : scope.row.order_status_trans == 2
                      ? "已付款"
                      : "已失效"
                  }}</el-tag
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="look_order(scope.row)"
                plain
                type="primary"
                size="small"
                >查看</el-button
              >
              <!-- <el-button @click="editer_order(scope.row)" type="success" plain size="small">编辑</el-button> -->
              <!-- <el-button @click="delete_order(scope.row)" type="danger" plain size="small">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page_numbox">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-change="page"
          :pager-count="11"
          :total="data_num"
          @current-change="change_page"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 弹窗 -->
    <Popup
      @downfun="down_popup"
      title="订单信息"
      :width="500"
      :height="430"
      :pop_show="popup_show"
    >
      <div class="shop_popbox">
        <div class="shop_info">
          <div class="shop_img">
            <img
              v-if="show_shopmesge.pic_url"
              :src="show_shopmesge.pic_url"
              alt=""
            />
          </div>
          <div class="shop_infolabel">
            <p class="shopname">
              {{ show_shopmesge.title }}
            </p>
            <p class="price">
              付款金额：<span>{{ show_shopmesge.payment }}</span>
            </p>
          </div>
        </div>
        <div class="ordermesagebox">
          <div class="ordermeglabel">
            <div class="order_msghead">
              <div class="icon">
                <span class="el-icon-s-order"></span>
              </div>
              <p>订单信息</p>
            </div>
            <div class="rece_listobx">
              <p class="list_info">订单号：{{ show_shopmesge.order_sn }}</p>
              <p class="list_info">
                商品id：<span class="blue_text">{{
                  show_shopmesge.product_id
                }}</span>
              </p>
            </div>

            <div class="rece_listobx">
              <p class="list_info">
                预估收益：<span class="blue_text"
                  >￥{{ show_shopmesge.amount }}</span
                >
              </p>
              <p class="list_info">
                收货时间：<span class="blue_text">{{
                  show_shopmesge.order_receive_time
                }}</span>
              </p>
            </div>

            <div class="rece_listobx">
              <p class="list_info">
                <span style="margin: auto 0;">订单状态：</span>
                <el-tag
                  :type="
                    show_shopmesge.order_status_trans == 1
                      ? 'warning'
                      : show_shopmesge.order_status_trans == 2
                      ? ''
                      : 'danger'
                  "
                  >{{
                    show_shopmesge.order_status_trans == 1
                      ? "已收货"
                      : show_shopmesge.order_status_trans == 2
                      ? "已付款"
                      : "已失效"
                  }}</el-tag
                >
              </p>
              <p class="list_info">支付时间：{{ show_shopmesge.pay_time }}</p>
            </div>
            <!-- <div class="rece_listobx">
              <p class="list_info">
                订单计入额：{{ show_shopmesge.point ? "￥" : ""
                }}{{ show_shopmesge.point }}
              </p>
              <p class="list_info">
                支付金额：
                <span class="red_text">￥{{ show_shopmesge.amount }}</span>
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
  import Popup from "../components/HelloWorld.vue"

  export default {
    name: "order",
    components: {
      Popup,
    },
    data() {
      return {
        table_height: 700,
        // 弹窗is_show
        popup_show: false,
        //tabl_databia表格数据
        tableData: [],
        // 页码
        page: 1,
        // 全部数据数量
        data_num: 0,

        // 开始时间
        begin_time: "",
        // 结束时间
        end_time: "",

        // 搜索订单号
        order_num: "",

        // 搜索订单状态
        order_status: "",

        // 下单日期
        date_value: "",
        // 状态文字
        status_text: "",

        show_shopmesge: {},
      }
    },
    created() {
      // 获取订单列表
      this.get_orderlistfun(1)
    },
    methods: {
      // 页码改变
      change_page(event) {
        this.page = event
        this.get_orderlistfun(event)
      },
      // 获取选择的时间
      get_choose_value(event) {
        let datearr = event.value
        datearr.forEach((item, t) => {
          if (t == 0) {
            // 开始时间
            let year = item.getFullYear()
            let month = item.getMonth() + 1

            if (month < 10) {
              month = "0" + month
            }
            let day = item.getDate()
            if (day < 10) {
              day = "0" + day
            }
            this.begin_time = year + "-" + month + "-" + day
          } else {
            // 开始时间
            let year = item.getFullYear()
            let month = item.getMonth() + 1

            if (month < 10) {
              month = "0" + month
            }
            let day = item.getDate()
            if (day < 10) {
              day = "0" + day
            }
            this.end_time = year + "-" + month + "-" + day
          }
        })
      },
      // table 操作
      look_order(item) {
        console.log(item)
        this.show_shopmesge = item
        this.popup_show = true
      },

      // 关闭弹窗
      down_popup() {
        this.popup_show = false
      },
      // 获取订单列表
      get_orderlistfun(page) {
        let data = {
          page: page,
          order_sn: this.order_num,
          begin: this.begin_time,
          end: this.end_time,
          order_status_trans: this.order_status,
        }
        let token = localStorage.getItem("token")
        let that = this
        this.http.post_request("/order/index", data, token).then((res) => {
          if (res.status === 200) {
            that.tableData = res.data
            that.data_num = res.num
            if (res.data.length == 0) {
              this.$message({
                type: "warning",
                message: "暂无数据",
              })
            }
          }
        })
      },

      // 立即搜索
      searchbtnfun() {
        // if (this.order_num == "" && this.begin_time == "" && this.end_time == "" && this.order_status == "") {
        // 	this.$message({
        // 		type: "warning",
        // 		message: "请输入查询条件",
        // 	})
        // 	return
        // }
        this.get_orderlistfun(this.page)
      },
    },
  }
</script>

<style>
  .order_page {
    height: calc(100% - 30px);
    padding: 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
  }

  /* 去掉table 的滚动条 */
  /* .el-table__body-wrapper::-webkit-scrollbar {
		display: none;
	} */

  .order_page .order_container::-webkit-scrollbar {
    display: none;
  }

  .order_page .order_container {
    height: 100%;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 20px;
    background-color: #ffffff;
  }

  .header_serachbox {
    display: flex;
  }

  .header_serachbox .list_input {
    display: flex;
    margin-right: 30px;
  }

  .header_serachbox .list_input .input_title {
    white-space: nowrap;
    font-size: 14px;
    color: #333333;
    margin: auto 8px auto 0;
  }

  .header_serachbox .list_input .choose_select span {
    display: flex;
  }

  /* .header_serachbox .list_input .el-dropdown-link .choose_select {
		height: 100%;
		font-size: 14px;
		padding: 8px 10px;
		border-radius: 4px;
		border: 1px solid #DCDFE6;
	}
 */
  .tablecontainer {
    width: 100%;
    margin-top: 20px !important;
  }

  .page_numbox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }

  .table_price {
    /* 	color: #ff9131; */
    color: #ff0000;
    font-size: 22px;
  }

  .endall_price {
    color: #0fc345;
    font-size: 22px;
  }

  .all_pricenum {
    color: #e6a23c;
    font-size: 22px;
  }

  .shop_table_title {
    width: 100%;
    /* border: 1px solid red; */
    /* order_title */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table_priceto span,
  .table_price span {
    font-size: 14px;
  }

  .table_priceto {
    color: #1677ff;
    font-size: 22px;
  }

  /*  订单信心弹窗 */
  .shop_popbox {
    width: 100%;
  }

  .shop_popbox .shop_info {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .shop_popbox .shop_info .shop_img {
    width: 130px;
    height: 130px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #d6d6d6;
    display: flex;
    border-radius: 5px;
  }

  .shop_popbox .shop_info .shop_img img {
    width: 100%;
    display: block;
    margin: auto;
    border-radius: 5px;
  }

  .shop_popbox .shop_info .shop_infolabel {
    width: 360px;
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    justify-content: space-between;
  }

  .shop_popbox .shop_info .shop_infolabel .shopname {
    font-size: 15px;
    line-height: 24px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .shop_popbox .shop_info .shop_infolabel .shop_number {
    font-size: 14px;
    display: flex;
  }

  .shop_popbox .shop_info .shop_infolabel .shop_number .num_title {
    margin: auto 0;
    font-size: 14px;
  }

  .shop_popbox .shop_info .shop_infolabel .shop_number .edit_numbox {
    display: flex;
    /* border: 1px solid red; */
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .list_showtext {
    display: flex;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .list_showtext
    i {
    font-size: 20px;
    color: #e57200;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .list_showtext
    span {
    margin: 5px 10px 0;
    display: block;
    font-size: 12px;
    color: #1677ff;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .edit_input {
    display: flex;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .edit_input
    input {
    border: 1px solid #c0c4cc;
    font-size: 14px;
    width: 80px;
    padding: 5px 10px;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .edit_input
    input:focus {
    outline: none;
    border: 1px solid #1677ff;
    border-radius: 4px;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .edit_input
    button {
    border: none;
    color: #1677ff;
    margin-left: 10px;
    background-color: #ffffff;
    padding: 0 10px;
    border-radius: 4px;
    transition-duration: 0.5s;
    border: 1px solid #1677ff;
  }

  .shop_popbox
    .shop_info
    .shop_infolabel
    .shop_number
    .edit_numbox
    .edit_input
    button:hover {
    background-color: #1677ff;
    color: #ffffff;
  }

  .shop_popbox .shop_info .shop_infolabel .shop_number i {
    font-size: 20px;
    color: #1677ff;
  }

  .shop_popbox .shop_info .shop_infolabel .price {
    font-size: 14px;
  }

  .shop_popbox .shop_info .shop_infolabel .price span::before {
    content: "￥";
    font-size: 14px;
  }

  .shop_popbox .shop_info .shop_infolabel .price span {
    font-size: 24px;
    color: rgb(255, 4, 4);
  }

  .shop_popbox .ordermesagebox {
    width: 100%;
  }

  .shop_popbox .ordermesagebox .receive_info {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #ededed;
  }

  .shop_popbox .ordermesagebox .receive_info .box_title {
    display: flex;
    margin-bottom: 10px;
  }

  .shop_popbox .ordermesagebox .receive_info .box_title .icon {
    background: #e3efff;
    width: 26px;
    height: 26px;
    display: flex;
    border-radius: 100%;
    margin: auto 5px auto 0;
  }

  .shop_popbox .ordermesagebox .receive_info .box_title .icon span {
    font-size: 14px;
    color: #1677ff;
    margin: auto;
  }

  .shop_popbox .ordermesagebox .receive_info .box_title p {
    font-size: 16px;
    color: #333333;
    margin: auto 0;
  }

  .shop_popbox .ordermesagebox .receive_info .receive_list {
    width: 100%;
    display: flex;
    padding: 0 26px;
    justify-content: space-between;
  }

  .shop_popbox .ordermesagebox .receive_info .receive_list .list_text {
    width: 48%;
    /* border: 1px solid red; */
    padding: 8px 0;
    font-size: 14px;
    line-height: 26px;
  }

  .shop_popbox .ordermeglabel {
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #ededed;
  }

  .shop_popbox .ordermeglabel .order_msghead {
    display: flex;
    padding: 20px 0;
  }

  .shop_popbox .ordermeglabel .order_msghead .icon {
    background: #e3efff;
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 100%;
    margin: auto 5px auto 0;
  }

  .shop_popbox .ordermeglabel .order_msghead .icon span {
    font-size: 18px;
    color: #1677ff;
    margin: auto;
  }

  .shop_popbox .ordermeglabel .order_msghead p {
    margin: auto 0;
    font-size: 16px;
  }

  .shop_popbox .ordermeglabel .rece_listobx {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .shop_popbox .ordermeglabel .rece_listobx .list_info {
    width: 48%;
    margin: auto 0;
    font-size: 14px;
    color: #333333;
    display: flex;
    padding: 5px 0;
    white-space: nowrap;
  }

  .shop_popbox .ordermeglabel .rece_listobx .list_info .lfet_title {
    margin: auto 0;
    font-size: 14px;
    color: #333333;
  }

  .shop_popbox .ordermeglabel .rece_listobx .list_info .order_statusset {
    width: 200px;
  }

  .shop_popbox .ordermeglabel .rece_listobx .list_info .red_text {
    color: #ff4d4f;
    font-weight: 500;
  }

  .shop_popbox .ordermeglabel .rece_listobx .list_info .blue_text {
    color: #1677ff;
    font-weight: 500;
    line-height: 20px;
  }

  .shop_popbox .ordermeglabel .rece_listobx .list_info .green_text {
    color: #51d709;
    font-weight: 500;
  }

  .shop_popbox .setOrderbox {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .shop_popbox .setOrderbox .show_btn {
    margin: auto;
    width: 34%;
    font-size: 16px;
  }

  .create_timetext {
    color: #999999;
  }

  .get_timetext {
    color: #ff9131;
  }

  .shop_img {
    width: 50px;
  }
</style>
