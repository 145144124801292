<template>
  <div class="owner_container">
    <!-- 编号 名称 负责人名称 头像 联系方式 创建时间 地址 -->
    <div class="owner_center">
      <div class="header_serachbox">
        <div class="list_input">
          <p class="input_title">负责人名称：</p>
          <el-input placeholder="请输入负责人名称" v-model="duty_nam" clearable>
          </el-input>
        </div>
        <!-- 	<div class="list_input">
					<p class="input_title">创建时间：</p>
					<el-date-picker v-model="date_value" @blur="get_choose_value" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div> -->
        <div class="list_input">
          <p class="input_title">手机号：</p>
          <el-input
            placeholder="请输入手机号"
            v-model="user_phonenumber"
            clearable
          >
          </el-input>
        </div>

        <div class="list_input">
          <p class="input_title">店铺名称：</p>
          <el-input placeholder="请输入店铺名称" v-model="shop_name" clearable>
          </el-input>
        </div>
        <div class="submit_btn">
          <el-button
            type="primary"
            @click="search_talefun"
            icon="el-icon-search"
            >立即搜索</el-button
          >
        </div>
      </div>

      <div class="tablecontainer">
        <el-table
          :data="tableData"
          border
          class="table_box"
          style="width: 100%"
          :height="'calc(100vh - 278px)'"
        >
          <!-- 	<el-table-column align="center" prop="id" label="编号" width="100">
					</el-table-column> -->
          <el-table-column
            align="center"
            prop="shopname"
            label="店铺名称"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="logo"
            label="店铺logo"
            width="120"
          >
            <template slot-scope="scope">
              <img class="avatar_img" :src="scope.row.logo" alt="" />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="realname"
            label="负责人姓名"
            width="130"
          >
          </el-table-column>

          <el-table-column
            align="center"
            prop="mobile"
            label="联系方式"
            width="140"
          >
          </el-table-column>

          <el-table-column
            align="center"
            prop="status"
            label="店铺状态"
            width="110"
          >
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{
                scope.row.status == 1 ? "正常" : "禁用"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createtime"
            label="创建时间"
            width="200"
          >
          </el-table-column>

          <el-table-column align="center" prop="province" label="地址">
            <template slot-scope="scope">
              <p>
                {{ scope.row.province }}-{{ scope.row.city }}-{{
                  scope.row.area
                }}
              </p>
            </template>
          </el-table-column>

          <!-- 	<el-table-column align="center" label="操作">
						<template slot-scope="scope">
							<el-button @click="delete_record(scope.row)" type="danger" plain size="small">删除</el-button>
						</template>
					</el-table-column>
 -->
        </el-table>
        <div class="page_numbox">
          <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="11"
            :total="data_num"
            @current-change="change_page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { upload_url } from "../ulist/api.js"
  export default {
    name: "owner",
    data() {
      return {
        pic_url: upload_url,

        tableData: [],
        page: 1,
        // 全部数据数量
        data_num: 1,

        // 负责人名称
        duty_nam: "",
        // 创建时间
        date_value: "",
        // 手机号
        user_phonenumber: "",
        // 店铺名称
        shop_name: "",
      }
    },
    created() {
      // for (let index = 0; index < 50; index++) {
      // 	let obj = {
      // 		index: index + 1,
      // 		shop_name: '店铺名称',
      // 		duty_nicnname: "王卫东",
      // 		avatar: "https://api-storage.4ce.cn/v1/3f43bf271aa3d313b263868d0aecc27a.jpg",
      // 		link_phone: "18876230238",
      // 		create_time: "2022-09-12 16:00:09",
      // 		addresstext: "陕西省西安市未央区张家堡街道"
      // 	};
      // 	this.tableData.push(obj)

      // }

      this.get_user_datalisfun(this.page)
    },
    methods: {
      // 获取选择的时间
      get_choose_value(event) {
        console.log(event.value)
        let datearr = event.value
        // let datestart = "";
        // let date_end = "";
        datearr.forEach((item, t) => {
          if (t == 0) {
            console.log(item.getFullYear())
            console.log(item.getMonth() + 1)
          } else {
            console.log(item.getFullYear())
            console.log(item.getMonth() + 1)
          }
        })
      },
      // 页码改变
      change_page(event) {
        console.log(event)
        this.page = event
        this.get_user_datalisfun(event)
      },
      // 获取商户列表
      get_user_datalisfun(page) {
        let token = localStorage.getItem("token")
        let data = {
          page: page,
          company: this.shop_name,
          mobile: this.user_phonenumber,
          linkname: this.duty_nam,
        }
        let that = this
        this.http.post_request("/merchant/index", data, token).then((res) => {
          if (res.status === 200) {
            that.tableData = res.data
            that.data_num = res.num
            if (res.data.length == 0) {
              this.$message({
                type: "warning",
                message: "暂无数据",
              })
            }
          } else {
            this.$message({
              type: "error",
              message: "请求出错，请重新获取",
            })
          }
        })
      },
      search_talefun() {
        if (
          this.shop_name == "" &&
          this.user_phonenumber == "" &&
          this.duty_nam == ""
        ) {
          this.$message({
            type: "warning",
            message: "请输入查询条件",
          })
          return
        }
        this.get_user_datalisfun(this.page)
      },
    },
  }
</script>

<style scoped>
  .owner_container {
    height: calc(100% - 30px);
    padding: 15px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
  }

  /* 去掉table 的滚动条 */
  /* .el-table__body-wrapper::-webkit-scrollbar {
		display: none;
	}
 */

  .owner_container .owner_center::-webkit-scrollbar {
    display: none;
  }

  .owner_container .owner_center {
    height: 100%;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 20px;
    background-color: #fff;
  }

  .header_serachbox {
    display: flex;
  }

  .header_serachbox .list_input {
    display: flex;
    margin-right: 30px;
  }

  .header_serachbox .list_input .input_title {
    white-space: nowrap;
    font-size: 14px;
    color: #333333;
    margin: auto 8px auto 0;
  }

  .header_serachbox .list_input .choose_select span {
    display: flex;
  }

  .tablecontainer {
    width: 100%;
    margin-top: 20px !important;
  }

  .tablecontainer .table_box .avatar_img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }

  .page_numbox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }
</style>
