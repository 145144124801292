<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  // import Index from "./views/index.vue";
  export default {
    name: "app",
    components: {
      // Index,
    },
    data() {
      return {
        login: window.localStorage.getItem("token"),
      }
    },
    beforeCreate() {},
    created() {},

    methods: {},
  }
</script>

<style>
  @import url("../src/assets/iconfont/iconfont.css");

  * {
    margin: 0;
    padding: 0;
  }

  a:focus {
    outline: none;
  }
</style>
