<template>
  <div class="home_page">
    <div class="page_container">
      <div class="top_infobox">
        <div class="left_version_box">
          <div class="v_heade">
            <span class="el-icon-guide"></span>
            <p>系统信息</p>
          </div>
          <div class="infobox">
            <p>{{ set_region_title }}</p>
            <p>开通日期：{{ create_time_text }}</p>
            <!-- 
            <div v-if="page_data.kaohe">
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                :content="page_data.kaohe.msg1"
              >
                <div class="time_mest" slot="reference">
                  <p>团队考核日期: {{ page_data.kaohe.date1 }}</p>
                  <span class="el-icon-info"></span>
                </div>
              </el-popover>
            </div> -->

            <div v-if="page_data.kaohe">
              <el-popover
                placement="top-start"
                width="200"
                trigger="hover"
                :content="page_data.kaohe.msg2"
              >
                <!-- <el-button slot="reference">hover 激活</el-button> -->
                <div class="time_mest" slot="reference">
                  <p>考核日期: {{ page_data.kaohe.date2 }}</p>
                  <span class="el-icon-info"></span>
                </div>
              </el-popover>
            </div>
            <!-- <p v-if="page_data.kaohe">
              考核状态：({{
                page_data.kaohe.status == 1
                  ? "考核中"
                  : page_data.kaohe.status == 2
                  ? "考核通过"
                  : page_data.kaohe.status == 3
                  ? "考核未通过"
                  : "已完成"
              }})
            </p>
            <p>当前版本：{{ versiontext }}</p> -->
          </div>
        </div>

        <div class="left_version_box" v-if="level >= 4 ? true : false">
          <div class="v_heade">
            <span class="el-icon-guide"></span>
            <p>
              考核中心
              <span v-if="page_data.kaohe">
                ({{
                  page_data.kaohe.status == 1
                    ? "考核中"
                    : page_data.kaohe.status == 2
                    ? "考核通过"
                    : page_data.kaohe.status == 3
                    ? "考核未通过"
                    : "已完成"
                }})</span
              >
            </p>
          </div>
          <div class="kaohebox" v-if="page_data.kaohe">
            <div class="list_mestbox">
              <p class="mest_title">消费流水</p>
              <div class="number_mest">
                <div class="list_mebx">
                  <p class="info_titlle">目标：</p>
                  <span class="big_num">{{ page_data.kaohe.amount1 }}</span>
                  <span class="sym_span">(万)</span>
                </div>
                <div class="list_mebx">
                  <p class="info_titlle">已完成：</p>
                  <span class="big_num">{{ page_data.kaohe.amount2 }}</span>
                  <span class="sym_span">(万)</span>
                </div>
              </div>
            </div>

            <div class="list_mestbox">
              <p class="mest_title">团队人数</p>
              <div class="number_mest">
                <div class="list_mebx">
                  <p class="info_titlle">目标：</p>
                  <span class="big_num">{{ page_data.kaohe.team_num1 }}</span>
                  <span class="sym_span">(人)</span>
                </div>
                <div class="list_mebx">
                  <p class="info_titlle">已完成：</p>
                  <span class="big_num">{{ page_data.kaohe.team_num2 }}</span>
                  <span class="sym_span">(人)</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="infobox">
            <p>团队人数 10（目标） / 3（已完成）</p>
            <p>消费流水 4万（目标） / 1万（已完成</p>
          </div> -->
        </div>

        <div
          class="righ_databox"
          :style="level >= 4 ? 'width:47%' : 'width:73%'"
        >
          <div class="headerbox">
            <span class="el-icon-s-data"></span>
            <p>数据统计</p>
          </div>
          <ul class="data_ulbox">
            <!-- <li class="list_data">
							<div class="label">
								<span class="iconfont icon-icf_user_add"></span>
								<p class="title">今日新增用户</p>
							</div>
							<p class="number_big" style="color: red;">0</p>
							<div class="all_data">
								<p>总计用户数：</p>
								<i>899人</i>
							</div>
						</li> -->
            <li class="list_data">
              <div class="label">
                <span class="iconfont icon-workorder-add"></span>
                <p class="title">今日订单数</p>
              </div>
              <p
                class="number_big"
                :style="
                  page_data.today_order < 1 ? 'color: red;' : 'color: #0cd80c;'
                "
              >
                {{ page_data.today_order }}
              </p>
              <div class="all_data">
                <p>总计订单数：</p>
                <i>{{ page_data.total_order }}笔</i>
              </div>
            </li>
            <li class="list_data">
              <div class="label">
                <span class="iconfont icon-i-money"></span>
                <p class="title">今日收款金额</p>
              </div>
              <p
                class="number_big"
                :style="
                  page_data.today_amount < 1 ? 'color: red;' : 'color: #0cd80c;'
                "
              >
                {{ page_data.today_amount }}
              </p>
              <div class="all_data">
                <p>总计收款：</p>
                <i>{{ page_data.total_amount }}元</i>
              </div>
            </li>
            <li class="list_data">
              <div class="label">
                <span class="iconfont icon-jinbishangchengyuan"></span>
                <p class="title">今日提现金额</p>
              </div>
              <p
                class="number_big"
                :style="
                  page_data.today_tixian < 1 ? 'color: red;' : 'color: #0cd80c;'
                "
              >
                {{ page_data.today_tixian }}
              </p>
              <div class="all_data">
                <p>总计提现：</p>
                <i>{{ page_data.total_tixian }}元</i>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="echarts">
        <div class="order_statiisticbox">
          <div class="staticsic_headebox">
            <div class="lfet_head">
              <div class="icon_box">
                <span class="el-icon-s-data"></span>
              </div>
              <p class="staic_title">订单统计</p>
            </div>
            <!-- <div class="choose_tab">
            <p
                :class="yearState == 0 ? 'list_staticactive' : 'list_static'"
                @click="set_option(0)"
              >
                周
              </p>
              <p
                :class="yearState == 1 ? 'list_staticactive' : 'list_static'"
                @click="set_option(1)"
              >
                月
              </p>
              <p
                :class="yearState == 2 ? 'list_staticactive' : 'list_static'"
                @click="set_option(2)"
              >
                年
              </p>
            </div> -->
          </div>
          <div id="line_echarts" ref="echarts_id" class="static_eachear"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import config from "../../package.json"
  import * as echarts from "echarts"
  export default {
    name: "home",
    data() {
      return {
        versiontext: config.version,
        // 年月周
        yearState: 0,

        // page_data
        page_data: {},

        user_data: {},
        set_region_title: "",
        create_time_text: "",

        level: 1,
      }
    },
    computed: {
      regin_title() {
        return this.$store.state.regin_title
      },
    },
    watch: {
      regin_title() {
        this.set_title()
      },
    },
    mounted() {
      this.user_data = this.$store.state.user_info

      window.onresize = () => {
        return (() => {
          this.set_echartssizefun()
        })()
      }
    },

    created() {
      this.set_title()

      this.get_topdatfun()
    },
    beforeDestroy() {
      window.removeEventListener("resize")
    },

    methods: {
      set_echartssizefun() {
        var line_chatmap = echarts.getInstanceByDom(this.$refs.echarts_id)
        line_chatmap.resize()
      },

      set_title() {
        this.set_region_title = this.$store.state.regin_title
      },
      // 年月日修改
      set_option(n) {
        if (this.yearState == n) {
          return
        }
        this.yearState = n
        if (n == 0) {
          let data = [
            // 年
            ["周一", 116],
            ["周二", 129],
            ["周三", 135],
            ["周四", 86],
            ["周五", 73],
            ["周六", 85],
            ["周末", 73],
          ]
          this.setoptiondata(data)
          return
        }
        if (n == 1) {
          let data = [
            // 月
            ["01", 116],
            ["02", 129],
            ["03", 135],
            ["04", 86],
            ["05", 73],
            ["06", 85],
            ["07", 73],
            ["08", 135],
            ["09", 86],
            ["10", 73],
            ["11", 85],
            ["12", 73],
            ["13", 116],
            ["14", 129],
            ["15", 135],
            ["16", 86],
            ["17", 73],
            ["18", 85],
            ["19", 73],
            ["20", 135],
            ["21", 86],
            ["22", 73],
            ["23", 85],
            ["24", 73],
            ["25", 116],
            ["26", 129],
            ["27", 135],
            ["28", 86],
            ["29", 73],
            ["30", 85],
            ["31", 73],
          ]
          this.setoptiondata(data)
          return
        }
        if (n == 2) {
          let data = [
            // 年
            ["2022-01", 116],
            ["2022-02", 129],
            ["2022-03", 135],
            ["2022-04", 86],
            ["2022-05", 73],
            ["2022-06", 85],
            ["2022-07", 73],
            ["2022-08", 135],
            ["2022-09", 86],
            ["2022-10", 73],
            ["2022-11", 85],
            ["2022-12", 73],
          ]
          this.setoptiondata(data)
          return
        }
      },

      setoptiondata(data) {
        var line_chatmap = echarts.getInstanceByDom(
          document.getElementById("line_echarts")
        )
        let option = line_chatmap.getOption() // 获取option数据
        let arr1 = []
        let arr2 = []
        data.forEach((item) => {
          arr1.push(item[0])
          arr2.push(item[1])
        })
        option.xAxis[0].data = arr1 // 找到data修改数据标题
        option.series[0].data = arr2 // 找到data修改数据
        line_chatmap.setOption(option, true) // 重新渲染
      },
      // 创建echarts
      createEchartsfun(data) {
        const line_chat = echarts.init(document.getElementById("line_echarts"))
        let dateList = []
        let valueList = []

        for (let key in data) {
          dateList.unshift(key)
          valueList.unshift(data[key])
        }

        // data.forEach((item) => {
        //   dateList.push(item[0])
        //   valueList.push(item[1])
        // })

        line_chat.setOption({
          visualMap: [
            {
              show: false,
              type: "continuous",
              seriesIndex: 0,
              min: 0,
              max: 0,
            },
          ],
          tooltip: {
            trigger: "axis",
          },
          lineStyle: {
            // 设置线条的style等
            color: "#0f3bff", // 折线线条颜色:红色
          },

          xAxis: {
            boundaryGap: false,
            data: dateList,
          },
          yAxis: {},
          grid: [
            {
              top: "30px",
              x: 46,
              y: 1,
              x2: 25,
              y2: 20,
            },
          ],
          series: [
            {
              symbol: "none",
              type: "line",
              data: valueList,
              areaStyle: {
                color: "#5395ff",
              },
              smooth: true,
              itemStyle: {
                noraml: {
                  color: "blue", //改变折线点的颜色
                  lineStyle: {
                    color: "blue", //改变折线颜色
                  },
                },
              },
            },
          ],
        })
      },
      // 获取顶部数据统计
      get_topdatfun() {
        let token = window.localStorage.getItem("token")
        this.http.post_request("/user/statistics", token).then((res) => {
          if (res.status == 200) {
            this.page_data = res.data
            this.level = res.data.kaohe.level
            this.create_time_text = res.data.kaohe.ktdate
            this.createEchartsfun(this.page_data.list)
          }
        })
      },
    },
  }
</script>
<style>
  .home_page::-webkit-scrollbar {
    display: none;
  }

  .home_page {
    overflow-y: scroll;

    height: 100%;
  }

  .home_page .page_container {
    padding: 15px;
    min-width: 900px;
  }

  .home_page .page_container .top_infobox {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .home_page .page_container .top_infobox .left_version_box {
    width: 25%;

    background-color: #fff;
    border-radius: 4px;
  }
  .home_page .page_container .top_infobox .left_version_box .kaohebox {
    display: flex;
  }

  .left_version_box .kaohebox .list_mestbox {
    width: 50%;
    position: relative;
    padding: 20px;
  }

  .left_version_box .kaohebox .list_mestbox .mest_title {
    font-size: 14px;
    color: #333333;
  }

  .left_version_box .kaohebox .list_mestbox .number_mest {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .left_version_box .kaohebox .list_mestbox .number_mest .list_mebx {
    padding: 10px;
    display: flex;
  }

  .number_mest .list_mebx .info_titlle {
    align-self: flex-end;
    font-size: 14px;
    padding-bottom: 6px;
  }

  .left_version_box .kaohebox .list_mestbox .number_mest .list_mebx .sym_span {
    align-self: flex-end;
    padding-bottom: 6px;
    font-size: 12px;
    margin: 0 4px;
  }

  .left_version_box .kaohebox .list_mestbox .number_mest .list_mebx .big_num {
    font-size: 26px;
    color: #1677ff;
  }
  .left_version_box .kaohebox .list_mestbox:first-child::after {
    position: absolute;
    width: 1px;
    background-color: #e8e5e5;
    height: 40%;
    content: "";
    display: block;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  .home_page .page_container .top_infobox .left_version_box .v_heade {
    display: flex;
    padding: 20px 20px 20px;
    border-bottom: 1px solid #e8e5e5;
  }

  .home_page .page_container .top_infobox .left_version_box .v_heade span {
    font-size: 15px;
    margin: auto 0;
    color: #1677ff;
  }

  .home_page .page_container .top_infobox .left_version_box .v_heade p {
    margin: auto 6px;
    font-size: 14px;
    color: #333333;
  }

  .home_page .page_container .top_infobox .left_version_box .infobox {
    padding: 20px;
  }
  .left_version_box .infobox .time_mest {
    display: flex;
    cursor: pointer;
  }
  .left_version_box .infobox .time_mest:hover p {
    color: #1677ff;
  }
  .left_version_box .infobox .time_mest p {
    line-height: 30px;
    font-size: 14px;
  }
  .left_version_box .infobox .time_mest span {
    margin: auto 0 auto 8px;
    font-size: 16px;
  }
  .home_page .page_container .top_infobox .left_version_box .infobox p {
    line-height: 30px;
    font-size: 14px;
  }

  .home_page .page_container .top_infobox .righ_databox {
    /* width: 47%; */
    background-color: #ffffff;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    flex-direction: column;
  }

  .home_page .page_container .top_infobox .righ_databox .headerbox {
    display: flex;
    padding: 20px 0px 20px 20px;
    border-bottom: 1px solid #d8d8d8;
  }

  .home_page .page_container .top_infobox .righ_databox .headerbox span {
    font-size: 15px;
    margin: auto 0;
    color: #1677ff;
  }

  .home_page .page_container .top_infobox .righ_databox .headerbox p {
    margin: auto 6px;
    font-size: 14px;
    color: #333333;
  }

  .home_page .page_container .top_infobox .righ_databox .data_ulbox {
    display: flex;
    padding: 20px;
    margin: auto 0;
    justify-content: space-between;
  }

  .home_page .page_container .top_infobox .righ_databox .data_ulbox .list_data {
    list-style: none;
    width: 22%;
    position: relative;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: -10%;
    background-color: #eaeaea;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data:last-child::after {
    display: none;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data
    .label {
    display: flex;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data
    .label
    span {
    font-size: 20px;
    color: #1677ff;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data
    .label
    p {
    font-size: 14px;
    margin: auto 0 auto 6px;
    color: #333333;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data
    .number_big {
    font-size: 32px;
    margin: 12px 0;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data
    .all_data {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .home_page
    .page_container
    .top_infobox
    .righ_databox
    .data_ulbox
    .list_data
    .all_data
    i {
    font-style: normal;
    font-size: 14px;
  }

  .echarts {
    width: 100%;
    margin-top: 30px;
  }

  .echarts .order_statiisticbox {
    padding: 15px 20px;
    background-color: #fff;
  }

  .echarts .order_statiisticbox .staticsic_headebox {
    display: flex;
    justify-content: space-between;
    height: 30px;
  }

  .echarts .order_statiisticbox .staticsic_headebox .lfet_head {
    display: flex;
  }

  .echarts .order_statiisticbox .staticsic_headebox .lfet_head .icon_box {
    display: flex;
    width: 26px;
    height: 26px;
    margin: auto 0;
    background-color: #e3efff;
    border-radius: 100%;
  }

  .echarts .order_statiisticbox .staticsic_headebox .lfet_head .icon_box span {
    margin: auto;
    font-size: 14px;
    color: #1677ff;
  }

  .echarts .order_statiisticbox .staticsic_headebox .lfet_head .staic_title {
    margin: auto 10px;
    font-size: 14px;
  }

  .echarts .order_statiisticbox .staticsic_headebox .choose_tab {
    display: flex;
    margin: auto 0;
  }

  .echarts .order_statiisticbox .staticsic_headebox .choose_tab .list_static {
    margin: 0 20px;
    display: block;
    width: 26px;
    height: 26px;
    background-color: #e3efff;
    color: #1677ff;
    cursor: pointer;
    transition-duration: 0.3s;
    border-radius: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 26px;
  }

  .echarts
    .order_statiisticbox
    .staticsic_headebox
    .choose_tab
    .list_staticactive {
    background-color: #1677ff;
    color: #ffffff;
    cursor: pointer;
    transition-duration: 0.3s;
    border-radius: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 26px;
    margin: 0 20px;
    display: block;
    width: 26px;
    height: 26px;
  }

  .echarts
    .order_statiisticbox
    .staticsic_headebox
    .choose_tab
    .list_static:hover {
    background-color: #1677ff;
    color: #ffffff;
  }

  .home_page .page_container .echarts .static_eachear {
    width: 100%;
    height: 560px;
  }
</style>
