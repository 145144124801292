<template>
	<div class="user_page">
		<div class="user_mainbox">
			<div class="header_serachbox">
				<div class="list_input">
					<p class="input_title">用户编号：</p>
					<el-input placeholder="请输入用户编号" v-model="user_number" clearable>
					</el-input>
				</div>
				<div class="list_input">
					<p class="input_title">创建时间：</p>
					<el-date-picker v-model="date_value" @blur="get_choose_value" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div class="list_input">
					<p class="input_title">手机号：</p>
					<el-input placeholder="请输入手机号" v-model="user_phonenumber" clearable>
					</el-input>
				</div>

				<div class="submit_btn">
					<el-button type="primary" icon="el-icon-search">立即搜索</el-button>
				</div>
			</div>
			<div class="tablecontainer">
				<el-table :data="tableData" border class="table_box" style="width: 100%"
					:height="'calc(100vh - 290px)'">
					>
					<el-table-column align="center" prop="index" label="编号" width="80">
					</el-table-column>
					<el-table-column align="center" prop="nickname" label="昵称" width="200">
					</el-table-column>
					<el-table-column align="center" prop="avatar" label="头像" width="120">
						<template slot-scope="scope">
							<img class="avatar_img" :src="scope.row.avatar" alt="" />
						</template>
					</el-table-column>
					<el-table-column align="center" prop="sex" label="性别" width="100">
						<template slot-scope="scope">
							<el-tag :type="scope.row.sex == 1 ? 'success' : 'warning'">{{ scope.row.sex == 1 ? "男" : "女"
                }}{{ scope.row.sex }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="openid" label="openid" width="260">
					</el-table-column>
					<el-table-column align="center" prop="link_phone" label="手机号" width="160">
					</el-table-column>
					<el-table-column align="center" prop="register_time" label="注册时间" width="220">
					</el-table-column>

					<el-table-column align="center" prop="region_city" label="所在地" width="300">
					</el-table-column>

					<el-table-column align="center" label="操作" width="200">
						<template slot-scope="scope">
							<el-button @click="look_user(scope.row)" plain type="primary" size="small">查看</el-button>
							<el-button @click="delete_user(scope.row)" type="danger" plain size="small">删除</el-button>
							<!-- 	<el-button @click="look_order(scope.row)" plain type="primary" size="small">查看</el-button>
							<el-button @click="delete_order(scope.row)" type="danger" plain size="small">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="page_numbox">
				<el-pagination background layout="prev, pager, next" :pager-count="11" :total="100"
					@current-change="change_page">
				</el-pagination>
			</div>
		</div>
		<Popup title="用户信息" :width="500" :height="560" @downfun="down_popup" :pop_show="popup_show">
			<div class="user_popbox">
				<div class="user_topbox">
					<div class="avatarbox">
						<img src="https://api.vvhan.com/api/acgimg" alt="" />
					</div>
					<div class="righ_nameinfo">
						<p class="list_name">沉默是今晚的康桥</p>
						<p class="list_info">
							<span class="tost_title">性别：</span>
							<el-tag class="listtag" type="success">男1</el-tag>
							<el-tag class="listtag" type="warning">女2</el-tag>
						</p>
						<p class="list_info">
							<span class="tost_title">手机号：</span>
							<i class="phone_text">12345678987</i>
						</p>
					</div>
				</div>
				<div class="other_infobox">
					<li class="list_otherbox">
						<p class="oth_title">open_id:</p>
						<div class="use_inputbox">
							<input type="text" style="color: #999999;" disabled value="oJZZD5KPy1tyN3hgk1ZYR8c7ljaI" />
						</div>
					</li>
					<li class="list_otherbox">
						<p class="oth_title">生日:</p>
						<div class="birthdaypciker">
							<el-date-picker class="date_picker" v-model="user_info.user_birthday" type="date"
								placeholder="选择生日">
							</el-date-picker>
						</div>
					</li>
					<li class="list_otherbox">
						<p class="oth_title">地区:</p>
						<div class="chose_area">
							<el-cascader style="width:100%;" :options="options" v-model="selectedOptions"
								@change="addressChoose"></el-cascader>
						</div>
					</li>
					<li class="list_otherbox">
						<p class="oth_title">注册时间:</p>
						<div class="use_inputbox">
							<input type="text" style="color: #999999;" disabled value="2022年09月09日" />
						</div>
					</li>
					<li class="list_otherbox">
						<p class="oth_title">上次登录:</p>
						<div class="use_inputbox">
							<input type="text" style="color: #999999;" disabled value="2023-09-23 15:00:00" />
						</div>
					</li>
				</div>
				<div class="setuserbtn">
					<el-button @click="down_popup" class="show_btn" type="primary">确认</el-button>
				</div>
			</div>
		</Popup>
	</div>
</template>

<script>
	import {
		regionData,
		codeToText
	} from "element-china-area-data"

	import Popup from "../components/HelloWorld.vue"
	export default {
		name: "user",
		components: {
			Popup,
		},
		data() {
			return {
				// 弹窗is_show
				popup_show: false,
				// 用户编号
				user_number: "",
				// 创建时间(搜索)
				date_value: "",
				// 手机号(搜索)
				user_phonenumber: "",

				//tabl_data表格数据
				tableData: [],

				user_info: {
					// 用户生日
					user_birthday: "",
				},

				// 省市区级联
				options: regionData,
				selectedOptions: [],
			}
		},
		created() {
			for (let index = 0; index < 50; index++) {
				let obj = {
					index: index + 1,
					nickname: "沉默是今晚的康桥",
					avatar: "https://api-storage.4ce.cn/v1/3f43bf271aa3d313b263868d0aecc27a.jpg",
					sex: index % 2 === 0 ? 1 : 2,
					openid: "oJZZD5KPy1tyN3hgk1ZYR8c7ljaI",
					link_phone: "17782520230",
					register_time: "2022年09月09日",
					region_city: "陕西省西安市未央区",
				}
				this.tableData.push(obj)
			}
		},

		methods: {
			// 页码改变
			change_page(event) {
				console.log(event)
				this.page = event
			},

			// 获取选择的时间
			get_choose_value(event) {
				console.log(event.value)
				let datearr = event.value
				// let datestart = "";
				// let date_end = "";
				datearr.forEach((item, t) => {
					if (t == 0) {
						console.log(item.getFullYear())
						console.log(item.getMonth() + 1)
					} else {
						console.log(item.getFullYear())
						console.log(item.getMonth() + 1)
					}
				})
			},
			// 查看用户详情
			look_user(item) {
				console.log(item)
				this.popup_show = true
			},

			/**省市区三级联动 */
			addressChoose(value) {
				console.log(
					"省市区：",
					codeToText[value[0]],
					codeToText[value[1]],
					codeToText[value[2]]
				)
			},

			// 关闭弹窗
			down_popup() {
				this.popup_show = false
			},

			// 删除用户
			delete_user(item) {
				console.log(item)
				this.$confirm("此操作将除该用户, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						this.$message({
							type: "error",
							message: "删除成功!",
						})
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						})
					})
			},
		},
	}
</script>

<style scoped>
	.user_page {
		height: calc(100% - 30px);
		padding: 15px;
		width: calc(100% - 30px);
		display: flex;
		flex-direction: column;
	}

	.user_page .user_mainbox::-webkit-scrollbar {
		display: none;
	}

	.user_mainbox {
		height: 100%;
		border-radius: 5px;
		overflow-y: scroll;
		padding: 20px;
		background-color: #ffffff;
	}

	.header_serachbox {
		display: flex;
	}

	.header_serachbox .list_input {
		display: flex;
		margin-right: 30px;
	}

	.header_serachbox .list_input .input_title {
		white-space: nowrap;
		font-size: 14px;
		color: #333333;
		margin: auto 8px auto 0;
	}

	.header_serachbox .list_input .choose_select span {
		display: flex;
	}

	.tablecontainer {
		width: 100%;
		margin-top: 30px !important;
	}

	.tablecontainer .table_box {}

	.tablecontainer .table_box .avatar_img {
		width: 50px;
		height: 50px;
		border-radius: 100%;
	}

	.user_popbox {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.user_popbox .user_topbox {
		width: 100%;
		display: flex;
	}

	.user_popbox .user_topbox .avatarbox {
		width: 100px;
		height: 100px;
		border-radius: 6px;
		margin-right: 20px;
	}

	.user_popbox .user_topbox .avatarbox img {
		width: 100%;
		height: 100%;
		border-radius: 6px;
	}

	.user_popbox .user_topbox .righ_nameinfo .list_name {
		font-size: 22px;
	}

	.user_popbox .user_topbox .righ_nameinfo .list_info {
		display: flex;
		margin-top: 16px;
	}

	.user_popbox .user_topbox .righ_nameinfo .list_info .tost_title {
		font-size: 14px;
		margin: auto 0;
	}

	.user_popbox .user_topbox .righ_nameinfo .list_info .listtag {
		margin: auto 0;
		height: auto;
		line-height: normal;
	}

	.user_popbox .user_topbox .righ_nameinfo .list_info .phone_text {
		font-style: normal;
		margin: auto 0;
		font-size: 14px;
		line-height: 20px;
		color: #1677ff;
	}

	.user_popbox .other_infobox {
		width: 100%;
		padding: 10px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.user_popbox .other_infobox .list_otherbox {
		margin: 10px 0;
		width: 100%;
		display: flex;
		list-style: none;
	}

	.user_popbox .other_infobox .list_otherbox .oth_title {
		font-size: 14px;
		white-space: nowrap;

		color: #333333;
		margin: auto 0;
		text-align: center;
		width: 20%;
	}

	.user_popbox .other_infobox .list_otherbox .birthdaypciker {
		width: 80%;
		/* border: 1px solid red; */
	}

	.user_popbox .other_infobox .list_otherbox .birthdaypciker .date_picker {
		width: 100%;
	}

	.user_popbox .other_infobox .list_otherbox .chose_area {
		width: 80%;
		/* border: 1px solid red; */
	}

	.user_popbox .other_infobox .list_otherbox>.use_inputbox {
		color: #333333;
		width: 80%;
		border: 1px solid #dcdfe6;
		border-radius: 6px;
		padding: 0;
		margin: auto 0;
	}

	.user_popbox .other_infobox .list_otherbox>.use_inputbox input {
		padding: 12px;
		border: none;
		font-size: 14px;
	}

	.user_popbox .setuserbtn {
		margin: 30px auto 0;
	}

	.user_popbox .setuserbtn .show_btn {
		width: 200px;
	}
</style>