<template>
	<div class='user_main'>
		<div class="main_page">
			<!-- <div class="list_user">
				<span class="left_title">头像</span>
				<div class="avatar_box">
					<el-upload class="avatar-uploader" :action="baseURL+'/Base/upload'" :show-file-list="false"
						:on-success="uploadAvatarSuccess" :on-error='upload_error'>
						<div class="avatar" v-if="imageUrl!=''">
							<img :src="baseURL+ imageUrl">
							<div class="showmask">
								<span class=" el-icon-plus avatar-uploader-icon"></span>
								<p>重新上传</p>
							</div>
						</div>
						<i v-if="imageUrl==''" class="el-icon-plus avatar-uploader-icon"></i>

					</el-upload>
					<span v-if="imageUrl==''" class="el-upload__tip" slot="tip">
						请上传头像
					</span>
				</div>
			</div>
			<div class="list_user">
				<span class="left_title">账号</span>
				<div class="right_input">
					<el-input v-model="phone" clearable placeholder="请输入账号"></el-input>
				</div>
			</div> -->
			<!-- 	<div class="list_user">
				<span class="left_title">名称</span>
				<div class="right_input">
					<el-input v-model="name_text" clearable placeholder="请输入名称"></el-input>
				</div>
			</div> -->
			<div class="list_user">
				<span class="left_title">当前密码</span>
				<div class="right_input">
					<el-input v-model="parsent_pwd" clearable show-password placeholder="请输入账号"></el-input>
				</div>
			</div>
			<div class="list_user">
				<span class="left_title">新的密码</span>
				<div class="right_input">
					<el-input v-model="new_pwd" clearable show-password placeholder="请输入名称"></el-input>
				</div>
			</div>
			<div class="list_user">
				<span class="left_title">确认密码</span>
				<div class="right_input">
					<el-input v-model="sure_pwd" clearable show-password placeholder="请输入账号"></el-input>
				</div>
			</div>


			<div class="submit_butn">
				<el-button class="submit" @click='way_setbtnfun' type="primary">立即修改</el-button>
			</div>
		</div>


	</div>
</template>

<script>
	import {
		upload_url
	} from "../ulist/api.js"
	export default {
		name: 'user_set',
		data() {
			return {
				baseURL: upload_url,
				// 头像
				imageUrl: "",
				// 账号
				phone: "",
				// 名称
				// name_text: "",
				// 当前密码
				parsent_pwd: "",
				// 新密码
				new_pwd: "",
				// 确认密码
				sure_pwd: "",


			}

		},
		methods: {
			// 头像上传成功
			uploadAvatarSuccess(event) {
				// console.log("成功" + event)
				if (event.status == 200) {
					this.imageUrl = event.data
				}

			},

			upload_error() {
				this.$message({
					type: 'error',
					message: '上传失败，请重新上传!'
				});
			},

			//文件上传之前获取的文件信息
			// beforeAvatarUpload(event) {
			// 	console.log(event)
			// },

			mesagefun(text) {
				this.$message({
					type: 'error',
					message: text
				});
			},

			way_setbtnfun() {
				console.log("立即修改")
				// if (this.imageUrl == '') {
				// 	this.mesagefun("请上传头像")
				// 	return false
				// }
				// if (this.phone == '') {
				// 	this.mesagefun("请输入您的账号")
				// 	return false
				// }
				if (this.parsent_pwd == '') {
					this.mesagefun("请输入当前密码")
					return false
				}
				if (this.new_pwd == '') {
					this.mesagefun("请输入新密码")
					return false
				}
				if (this.sure_pwd == '') {
					this.mesagefun("请再次输入新密码")
					return false
				}
				if (this.new_pwd != this.sure_pwd) {
					this.mesagefun("两次新密码不一致，请核对后再试")
					return false
				}
				let data = {
					password: this.new_pwd,
					oldpassword: this.parsent_pwd,
				}
				let token = localStorage.getItem("token")

				this.http.post_request("/user/password", data, token).then(res => {
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: "修改成功"
						});
					}

				})

			},

		}
	}
</script>

<style>
	.user_main {
		height: calc(100% - 30px);
		padding: 15px;

		display: flex;
		flex-direction: column;
	}

	.user_main .main_page {
		margin: auto;
		padding: 40px;
		width: calc(100% - 80px);
		height: calc(100% - 80px);
		background-color: #fff;

	}

	.submit_butn {
		width: 480px;
		display: flex;
		margin-top: 60px;
		justify-content: center;
	}

	.submit_butn .submit {
		margin: auto;
		width: 140px;
	}


	.user_main .main_page .list_user {
		display: flex;
		margin-bottom: 30px;

	}

	.user_main .main_page .list_user .left_title {
		margin: auto 20px auto 0;
		font-size: 14px;
		color: #000000;
		display: block;
		text-align: right;
		width: 100px;
	}

	.user_main .main_page .list_user .left_title::before {
		content: "*";
		color: red;
		font-size: 16px;
	}

	.user_main .main_page .list_user .avatar_box {
		position: relative;
	}

	.user_main .main_page .list_user .avatar_box .avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		z-index: 10;
		overflow: hidden;
		width: 100px;
		z-index: 10;
		height: 100px;
	}

	.user_main .main_page .list_user .avatar_box .avatar-uploader .el-upload {
		height: 100px;
		width: 100px;
	}

	.user_main .main_page .list_user .avatar-uploader:hover {
		border-color: #409EFF;
	}

	.user_main .main_page .list_user .avatar-uploader .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}




	.user_main .main_page .list_user .el-upload__tip {
		height: 30px;
		text-align: center;
		font-size: 12px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		color: #8c939d;
	}

	.user_main .main_page .list_user .avatar-uploader .avatar {
		width: 100%;
		height: 100%;
		position: relative;

	}

	.user_main .main_page .list_user .avatar-uploader .avatar img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.user_main .main_page .list_user .avatar-uploader .avatar .showmask {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		top: 0;
		z-index: -10;
		display: flex;
		/* transition-duration: 0.5s; */
	}

	.user_main .main_page .list_user .avatar-uploader .avatar .showmask span {
		margin: auto;
		font-size: 28px;
		color: #ffffff;
	}

	.user_main .main_page .list_user .avatar-uploader .avatar .showmask p {
		height: 30px;
		position: absolute;
		bottom: 0;
		left: 0;
		text-align: center;
		width: 100%;
		color: #ffffff;
		font-size: 12px;
	}

	.user_main .main_page .list_user .avatar-uploader .avatar:hover .showmask {
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	.user_main .main_page .list_user .right_input {
		width: 360px;
		/* height: 30px; */
	}
</style>