import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user_info: {},
		count: 0,
		regin_title: "",
		create_time_text: "",
	},
	mutations: {
		user_infodata(state, user_info) {
			state.user_info = user_info
		},
		changecount(state, data) {
			state.count = data
		},
		regintitle(state, title) {
			state.regin_title = title
		},
		setcreate_time(state, time) {
			state.create_time_text = time
		}
	},
	actions: {},
	modules: {}
})